import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { fetchEvaluationScores } from '../../redux/actions/evaluationActions';
import Loader from '../common/Loader';
import ScoreBar from './ScoreBar';
import ReportScoreRow from './ReportScoreRow';
// import {
//   EVALUATIONS_REPORTS_MODULAR_ROUTE,
//   EVALUATIONS_REPORTS_MINIMALISTIC_ROUTE,
//   EVALUATIONS_REPORTS_SCALABLE_ROUTE,
//   EVALUATIONS_REPORTS_SEMANTIC_ROUTE,
//   EVALUATIONS_REPORTS_STYLISTICALLY_CONSISTENT_ROUTE,
// } from '../../core/constants';

const scoreBarScale40 = [
  { title: 'VERY POOR', score: '1 - 8' },
  { title: 'POOR', score: '8 - 16' },
  { title: 'FAIR', score: '16 - 24' },
  { title: 'GOOD', score: '24 - 32' },
  { title: 'VERY GOOD', score: '32 - 40' },
];

const scoreBarScale50 = [
  { title: 'VERY POOR', score: '1 - 10' },
  { title: 'POOR', score: '10 - 20' },
  { title: 'FAIR', score: '20 - 30' },
  { title: 'GOOD', score: '30 - 40' },
  { title: 'VERY GOOD', score: '40 - 50' },
];

// const reportLinks = {
//   Minimalistic: EVALUATIONS_REPORTS_MINIMALISTIC_ROUTE,
//   Semantic: EVALUATIONS_REPORTS_SEMANTIC_ROUTE,
//   Scalable: EVALUATIONS_REPORTS_SCALABLE_ROUTE,
//   Modular: EVALUATIONS_REPORTS_MODULAR_ROUTE,
//   'Stylistically Consistent': EVALUATIONS_REPORTS_STYLISTICALLY_CONSISTENT_ROUTE,
// };

function EvaluationReports({ taskId }) {
  const dispatch = useDispatch();
  const { scores, loading } = useSelector(state => state.evaluation);
  const overallScore = scores.reduce((acc, v) => acc + v.score, 0);

  useEffect(() => {
    dispatch(fetchEvaluationScores(taskId));
  }, [dispatch, taskId]);

  // const getButtonLink = reportName => reportLinks[reportName];

  if (loading) {
    return <Loader />;
  }

  const maxScore = scores.reduce((acc, v) => acc + v.max_score, 0);
  const scoreBarScale = maxScore === 40 ? scoreBarScale40 : scoreBarScale50;

  return (
    <Grid container justifyContent="center">
      <Grid className="py-4" item xs={10}>
        <ScoreBar title="Your Overall Score" score={overallScore} maxScore={maxScore} scale={scoreBarScale} />
      </Grid>

      <Grid item xs={10} lg={7}>
        {scores.map(report => (
          <ReportScoreRow
            key={report.name}
            reportName={report.name}
            maxScore={report.max_score}
            score={report.score}
            // btnLink={getButtonLink(report.name)}
          />
        ))}
      </Grid>
    </Grid>
  );
}

EvaluationReports.propTypes = {
  taskId: PropTypes.string.isRequired,
};

export default EvaluationReports;

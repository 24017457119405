import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Box, Grid, IconButton, Typography,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { MAIN_UI_COLOR } from '../../config';

const styles = () => ({
  root: {
    background: MAIN_UI_COLOR,
    padding: '20px 24px',
    color: '#FFFFFF',
    margin: 0,
  },
  closeButton: {
    justifyContent: 'flex-end',
    color: '#FFFFFF',
    padding: '4px',
  },
});

const RyffineDialogTitle = withStyles(styles)(props => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h6">{children}</Typography>
        </Grid>

        <Grid item xs={6}>
          <Box
            style={{
              justifyContent: 'flex-end',
              alignItems: 'center',
              display: 'flex',
              height: '100%',
            }}
          >
            {onClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </MuiDialogTitle>
  );
});

RyffineDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
};

export default RyffineDialogTitle;

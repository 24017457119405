import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import CreateTaskDialog from './CreateTaskDialog';
import { ENTITY_TYPE_FILE } from '../../core/entities';
import {
  createFluidTopicsImportTask,
  fetchFluidTopicsPing,
} from '../../redux/actions/taskActions';
import { applySearch, parsePath } from '../../core/services/fileStorageService';
import { getObjectsFromPath, getPathEntity } from '../../redux/actions/fileStorageActions';
import { TASKS_ROUTE } from '../../core/constants';
import { getCorrectPath } from '../common/Utility';

const STEP_FIELD_TYPE_MULTIPLE = 'multiple';
const STEP_FIELD_TYPE_PATH = 'path';

function filterObjects(objects, searchValue) {
  return applySearch(objects, searchValue).sort((a, b) => b.type.localeCompare(a.type));
}

const validationSchema = () => {
  const schema = {
    serverUrl: yup.string()
      .required('The server URL is required')
      .test(
        'allowed-value',
      <>
        The allowed value should be in the next format:
        <br />
        <b>http(s)://(domain or server_ip_address):(port is optional)/(company name)/</b>
      </>,
      v => /^(?:http(s)?:\/\/)[\w.-]+(:?[0-9]+)?(\/)[\w.-]+(\/)$/.test(v),
      ),
    apiKey: yup.string().required('The server API key is required'),
    sourceFilePath: yup.string()
      .required('Source file path is required')
      .test(
        'start_end_with_space',
        'The element of the source file path can not start or end with spaces',
        function (v) {
          const value = getCorrectPath(v, this.parent.sourceFilePathSearchValue);

          let result = true;
          value.split('/').forEach(path => {
            if (path.startsWith(' ') || path.endsWith(' ')) {
              result = false;
            }
          });

          return result;
        },
      )
      .test(
        'allowed-characters',
      <>
        Source file name can contain only the next characters:
        <br />
        {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
      </>,
      function (v) {
        const { sourceFilePathSearchValue } = this.parent;
        const value = `${v}/${sourceFilePathSearchValue}`;

        return /^[a-zA-Z0-9\s/\-._]+$/.test(value);
      },
      )
      .test(
        'not-new-lines',
        'Source file name cannot contain the new lines',
        function (v) {
          const { sourceFilePathSearchValue } = this.parent;
          const value = `${v}/${sourceFilePathSearchValue}`;

          return value && !value.includes('\n');
        },
      )
      .test(
        'file-exist',
        'Source file does not exist or is not a file entity',
        async function (v) {
          const { sourceFilePathSearchValue } = this.parent;
          const value = `${v}/${sourceFilePathSearchValue}`;

          if (value) {
            const response = await getPathEntity(value);

            return response.status === 200 && response.data.data.type === ENTITY_TYPE_FILE;
          }

          return false;
        },
      ),
  };

  return yup.object(schema);
};

const generateInitValues = task => {
  const config = JSON.parse(task.config || '{}');

  const [sourceFilePath, sourceFilename] = parsePath(task.source || '');

  return {
    // external settings
    apiKey: '',
    serverUrl: config.server_url || '',

    sourceFilePath: sourceFilePath || '',
    sourceFilePathSearchValue: sourceFilename || '',
    sourceFilePathObjects: [],
  };
};

function CreateFluidTopicsDialog(props) {
  const {
    open,
    onClose,
    rerunTask,
    resetOnClose,
  } = props;

  const dispatch = useDispatch();

  const [creatingTask, setCreatingTask] = useState(false);
  const [loadingSourceFileObjects, setLoadingSourceFileObjects] = useState(false);

  const [firstStepError, setFirstStepError] = useState('');

  const formik = useFormik({
    initialValues: generateInitValues(rerunTask || {}),
    validationSchema: validationSchema(),
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
  });

  const handleClose = link => {
    onClose(link);

    formik.resetForm();
  };

  const onFormSubmit = values => {
    setCreatingTask(true);

    const task = {
      source: `${values.sourceFilePath}${values.sourceFilePath.endsWith('/') ? '' : '/'}${values.sourceFilePathSearchValue}`,
      server_url: values.serverUrl,
      api_key: values.apiKey,
    };

    dispatch(createFluidTopicsImportTask(task))
      .then(res => handleClose(`${TASKS_ROUTE}/${res.id}`))
      .catch(() => setCreatingTask(false));
  };

  const onCreateButtonClick = () => {
    if (creatingTask) return;
    setCreatingTask(true);

    onFormSubmit(formik.values);
  };

  const onChangePathValue = (value, fieldName, searchFieldName) => {
    const split = value.split('/');

    const search = split[split.length - 1];
    const path = `${split.slice(0, -1).join('/')}`;

    if (path !== '/' || formik.values[fieldName] !== path) formik.setFieldValue(fieldName, path);
    formik.setFieldValue(searchFieldName, search).then(() => formik.validateField(fieldName));
  };

  const config = {
    title: 'Create Fluid Topics Import',
    steps: [
      {
        type: STEP_FIELD_TYPE_MULTIPLE,
        title: 'General settings',
        fields: [
          {
            onChange: event => formik.handleChange(event),
            value: formik.values.serverUrl,
            error: formik.errors.serverUrl,
            placeholder: 'Server URL',
            label: 'Server URL',
            key: 'serverUrl',
          },
          {
            onChange: event => formik.handleChange(event),
            value: formik.values.apiKey,
            error: formik.errors.apiKey,
            placeholder: 'API Key',
            label: 'API Key',
            key: 'apiKey',
          },
        ],
        stepTooltip: 'This is an initial version of integration with the Fluid Topics. The current implementation allows publishing only DITA content.',
        isValid: (formik.values.serverUrl !== '' && formik.errors.serverUrl === undefined)
          && (formik.values.apiKey !== '' && formik.errors.apiKey === undefined),
        async onSubmit() {
          const value = await fetchFluidTopicsPing(formik.values.serverUrl, formik.values.apiKey);

          if (!value) {
            setFirstStepError('Unable to connect to the server. Please check if server is running and credentials are correct.');
          }

          return value;
        },
        allowContinue: true,
        error: firstStepError,
        loading: false,
      },
      {
        title: 'Source file path',
        type: STEP_FIELD_TYPE_PATH,
        pathField: {
          objects: filterObjects(
            formik.values.sourceFilePathObjects, formik.values.sourceFilePathSearchValue,
          ),
          onChange: value => onChangePathValue(value, 'sourceFilePath', 'sourceFilePathSearchValue'),
          value: formik.values.sourceFilePath !== ''
            ? `${formik.values.sourceFilePath}${formik.values.sourceFilePath.endsWith('/') ? '' : '/'}${formik.values.sourceFilePathSearchValue}`
            : formik.values.sourceFilePathSearchValue,
          error: formik.errors.sourceFilePath,
          loading: false,
        },
        allowContinue: formik.values.sourceFilePath !== '',
        isValid: formik.errors.sourceFilePath === undefined,
        onSubmit: () => Promise.resolve(true),
        loading: loadingSourceFileObjects,
      },
    ],
    onSubmit: () => onCreateButtonClick(),
    allowAnyStepSelection: true,
    loading: creatingTask,
    allowContinue: true,
    initActiveStep: 0,
  };

  useEffect(() => {
    if (open) {
      setLoadingSourceFileObjects(true);

      getObjectsFromPath(`/${formik.values.sourceFilePath}`)
        .then(objects => {
          formik.setFieldValue('sourceFilePathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('sourceFilePath').then(() => setLoadingSourceFileObjects(false));
            });
        });
    }
  }, [formik.values.sourceFilePath, open]);

  useEffect(() => {
    if (open && formik.values.serverUrl !== '') formik.validateField('serverUrl');
  }, [formik.values.serverUrl]);

  useEffect(() => {
    if (open && formik.values.apiKey !== '') formik.validateField('apiKey');
  }, [formik.values.apiKey]);

  return (
    <CreateTaskDialog
      open={open}
      config={config}
      onClose={() => {
        onClose();

        if (resetOnClose) {
          formik.setValues(generateInitValues({}));
        }
      }}
      resetOnClose={resetOnClose}
    />
  );
}

CreateFluidTopicsDialog.defaultProps = { rerunTask: {}, resetOnClose: true };

CreateFluidTopicsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  resetOnClose: PropTypes.bool,
  rerunTask: PropTypes.object,
};

export default CreateFluidTopicsDialog;

import { ENABLED_TASKS } from '../config';

export const ACTION_TYPE_CHECKBOX = 'checkbox';
export const ACTION_TYPE_RADIO = 'radio';
export const ACTION_TYPE_NONE = 'none';
export const ACTION_TYPES = [ACTION_TYPE_CHECKBOX, ACTION_TYPE_RADIO, ACTION_TYPE_NONE];

export const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100];
export const ROWS_PER_PAGE_DEFAULT = 10;

export const BACK_PATHNAME_COOKIE_NAME = 'backPathname';

export const FILE_STORAGE_ROUTE = '/file-storage';
export const TASKS_ROUTE = '/tasks';
export const CREATE_TASK_ROUTE = `${TASKS_ROUTE}/create`;
export const CONVERSIONS_ROUTE = '/conversions';

export const VALIDATIONS_ROUTE = '/validations';
export const REPORTS_ROUTE = '/reports';
export const EVALUATIONS_ROUTE = '/evaluations';
export const EVALUATIONS_REPORTS_MODULAR_ROUTE = `${EVALUATIONS_ROUTE}/reports/modular`;
export const EVALUATIONS_REPORTS_MINIMALISTIC_ROUTE = `${EVALUATIONS_ROUTE}/reports/minimalistic`;
export const EVALUATIONS_REPORTS_SCALABLE_ROUTE = `${EVALUATIONS_ROUTE}/reports/scalable`;
export const EVALUATIONS_REPORTS_SEMANTIC_ROUTE = `${EVALUATIONS_ROUTE}/reports/semantic`;
export const EVALUATIONS_REPORTS_STYLISTICALLY_CONSISTENT_ROUTE = `${EVALUATIONS_ROUTE}/reports/stylistically-consistent`;
export const PREFERENCES_ROUTE = '/preferences';
export const OXYGEN_COMPLETENESS_CHECK_ROUTE = '/oxygen-completeness-check';
export const OXYGEN_BATCH_CONVERTER_ROUTE = '/oxygen-batch-converter';
export const OXYGEN_METRIC_REPORT_ROUTE = '/oxygen-metric-report';
export const ADMIN_REGRESSION_ROUTE = '/admin-regression';
export const ADMIN_USERS_ROUTE = '/admin-users';
export const ADMIN_PACKAGES_ROUTE = '/admin-packages';

export const KION_SDL_ALLOWED_OBJECT_DOMAINS = ['Technical', 'Diagnostic'];

export const SDL_ALLOWED_LANGUAGES = [
  'ar-AE', 'de-DE', 'en-GB', 'en-US', 'es-ES', 'es-MX', 'fr-CA', 'fr-FR', 'it-IT', 'ja-JP', 'ko-KR', 'nl-NL', 'zh-CN',
];

export const SDL_ALLOWED_IMAGE_RESOLUTIONS = [
  'High', 'Low', 'Multimedia', 'Source', 'Thumbnail',
];

export const DATE_FORMAT = 'yyyy-MM-dd';

export const TYPE_GITHUB = 'GitHub';
export const TYPE_FLUID_TOPICS = 'Fluid Topics';
export const TYPE_SDL = 'Tridion';
export const TYPE_KION_SDL = 'Kion Tridion';
export const TYPE_PALIGO = 'Paligo';
export const TYPE_PANDOC = 'Pandoc';
export const TYPE_IXIASOFT = 'Ixiasoft';
export const TYPE_CONVERSION = 'Conversion';
export const TYPE_NORMALIZATION = 'Normalization';

export const STATUSES_FILTER = {
  // UNKNOWN: 'Unknown',
  // PROVISIONING: 'Provisioning',
  PENDING: 'Pending',
  RUNNING: 'Running',
  INTERRUPTED: 'Interrupted',
  // DEPROVISIONING: 'Deprovisioning',
  FINISHED: 'Finished',
};

const TASKS_TYPES = [
  {
    key: TYPE_CONVERSION,
    enabled: ENABLED_TASKS.conversion,
  },
  {
    key: TYPE_NORMALIZATION,
    enabled: ENABLED_TASKS.normalization,
  },
  {
    key: TYPE_PANDOC,
    enabled: ENABLED_TASKS.pandoc,
  },
  {
    key: TYPE_IXIASOFT,
    enabled: ENABLED_TASKS.ixiasoft,
  },
  {
    key: TYPE_PALIGO,
    enabled: ENABLED_TASKS.paligo,
  },
  {
    key: TYPE_SDL,
    enabled: ENABLED_TASKS.sdl,
  },
  {
    key: TYPE_KION_SDL,
    enabled: ENABLED_TASKS.kion_sdl,
  },
  {
    key: TYPE_FLUID_TOPICS,
    enabled: ENABLED_TASKS.fluidTopics,
  },
  {
    key: TYPE_GITHUB,
    enabled: ENABLED_TASKS.gitHub,
  },
];

export const TYPES_FILTER = TASKS_TYPES.filter(i => i.enabled).map(i => i.key);

import React from 'react';
import { useSelector } from 'react-redux';
import {
  Button, Grid, makeStyles, Typography, withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';
import red from '@material-ui/core/colors/red';
import ScoreBar from './ScoreBar';

const DetailRow = withStyles(theme => ({
  text: {
    flex: 1,
    margin: theme.spacing(2),
  },
  img: {
    color: red[900],
    fontSize: 45,
  },
}))(({ classes }) => (
  <Grid container alignItems="center">
    <ErrorIcon className={classes.img} />
    <Typography className={classes.text} variant="body1" color="textSecondary">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporxsa,
      xsla cdsmc csmdklcmsd
    </Typography>
    <Button color="primary" variant="outlined" size="large">View source file</Button>
  </Grid>
));

const useStyles = makeStyles(() => ({
  detailTitle: {
    fontWeight: 'bold',
  },
}));

const scoreBarScale = [
  { title: 'VERY POOR', score: 1 },
  { title: 'POOR', score: 3 },
  { title: 'FAIR', score: 5 },
  { title: 'GOOD', score: 8 },
  { title: 'VERY GOOD', score: 10 },
];

const details = [1, 2, 3, 4, 5, 6];

function EvaluationScoreReport(props) {
  const classes = useStyles();
  const report = useSelector(state => state.evaluation.scores)
    .find(r => r.name === props.score) || {};
  const score = report.score || 0;

  return (
    <Grid container justifyContent="center">
      <Grid item xs={10}>
        <ScoreBar
          title={props.title}
          score={score}
          maxScore={10}
          scale={scoreBarScale}
          titleVariant="secondary"
        />
      </Grid>
      <Grid className="mt-4" item xs={10}>
        <Typography className={classes.detailTitle} variant="h4" color="primary">
          Improve Your Score
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Ryffiner has identified 6 issues that need your attention.
        </Typography>
        <Grid className="my-4" container justifyContent="center">
          <Grid item lg={9}>
            {details.map(detail => (
              <DetailRow key={detail} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

EvaluationScoreReport.propTypes = {
  title: PropTypes.PropTypes.string.isRequired,
  score: PropTypes.PropTypes.string.isRequired,
};

export default EvaluationScoreReport;

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import {
  MenuItem, Button, IconButton, Typography, Checkbox, ListItemText,
} from '@material-ui/core';
import * as yup from 'yup';
import { xml2js } from 'xml-js';
import { MdOutlineAddCircle } from 'react-icons/md';
import Placeholder from '../common/Placeholder';
import CreateTaskDialog from './CreateTaskDialog';
import AddParameterDialog from './AddParameterDialog';
import { TASKS_ROUTE } from '../../core/constants';
import { applySearch, parsePath } from '../../core/services/fileStorageService';
import { createTask, fetchConversionTypes, fetchConversionTags } from '../../redux/actions/taskActions';
import {
  checkFileExist, getObjectsFromPath, getPathEntity, readFile,
} from '../../redux/actions/fileStorageActions';
import { ENTITY_TYPE_FILE, TASK_ENGINE_PYTHON, TASK_ENGINE_XSLT } from '../../core/entities';
import { getCorrectPath } from '../common/Utility';
import { formatDateStr } from '../../core/services/commonService';
import { conversionTagTooltip, displayTagValue } from '../../core/services/taskConversionService';
import { SHOW_TAG_LAST_MODIFIED_DATE } from '../../config';

const STEP_FIELD_TYPE_MULTIPLE = 'multiple';
const STEP_FIELD_TYPE_PATH = 'path';

const MAX_NUMBER_OF_STEPS = 5;

const sortAlphaNum = (a, b) => String(a).localeCompare(String(b), 'en', { numeric: true });

const validationSchema = yup.object({
  conversionTag: yup.string().required('Release is required'),
  conversionType: yup.string().required('Conversion type is required'),
  outputFilename: yup.string()
    .required('Output file name is required')
    .test(
      'start_end_with_space',
      'The output filename can not start or end with spaces',
      v => !(v.startsWith(' ') || v.endsWith(' ')),
    )
    .test(
      'allowed-characters',
    <>
      Output file name can contain only the next characters:
      <br />
      {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
    </>,
    v => /^[a-zA-Z0-9\s/\-._]+$/.test(v),
    )
    .test('not-new-lines', 'Output file name cannot contain the new lines', v => v && !v.includes('\n'))
    .test('is-zip', "Output file name should end with '.zip'", v => v && v.endsWith('.zip'))
    .test('not-starts-with-slash', "Output file name cannot contain '/'", v => v && !v.includes('/'))
    .test('is-file', 'Output file name cannot consist of an extension only', v => v && v !== '.zip'),
  sourceFilePath: yup.string()
    .required('Source file path is required')
    .test(
      'start_end_with_space',
      'The element of the source file path can not start or end with spaces',
      function (v) {
        const value = getCorrectPath(v, this.parent.sourceFilePathSearchValue);

        let result = true;
        value.split('/').forEach(path => {
          if (path.startsWith(' ') || path.endsWith(' ')) {
            result = false;
          }
        });

        return result;
      },
    )
    .test(
      'allowed-characters',
    <>
      Source file name can contain only the next characters:
      <br />
      {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
    </>,
    function (v) {
      const { sourceFilePathSearchValue } = this.parent;
      const value = `${v}/${sourceFilePathSearchValue}`;

      return /^[a-zA-Z0-9\s/\-._]+$/.test(value);
    },
    )
    .test(
      'not-new-lines',
      'Source file name cannot contain the new lines',
      function (v) {
        const { sourceFilePathSearchValue } = this.parent;
        const value = `${v}/${sourceFilePathSearchValue}`;

        return value && !value.includes('\n');
      },
    )
    .test(
      'file-exist',
      'Source file does not exist or is not a file entity',
      async function (v) {
        const { sourceFilePathSearchValue } = this.parent;
        const value = `${v}/${sourceFilePathSearchValue}`;

        if (value) {
          const response = await getPathEntity(value);

          return response.status === 200 && response.data.data.type === ENTITY_TYPE_FILE;
        }

        return false;
      },
    ),
  outputFolderPath: yup.string()
    .test(
      'required',
      'Output folder path is required',
      function (v) {
        const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

        return value && value !== '';
      },
    )
    .test(
      'start_end_with_space',
      'The element of the output folder path can not start or end with spaces',
      function (v) {
        const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

        let result = true;
        value.split('/').forEach(path => {
          if (path.startsWith(' ') || path.endsWith(' ')) {
            result = false;
          }
        });

        return result;
      },
    )
    .test(
      'allowed-characters',
    <>
      Output folder can contain only the next characters:
      <br />
      {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
    </>,
    function (v) {
      const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

      return /^[a-zA-Z0-9\s/\-._]+$/.test(value);
    },
    )
    .test(
      'not-new-lines',
      'Output folder name cannot contain the new lines',
      function (v) {
        const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

        return value && !value.includes('\n');
      },
    )
    .test(
      'not-contain-extension',
      'Output folder name cannot contain the extensions',
      function (v) {
        const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

        return value && !value.includes('.zip');
      },
    )
    .test(
      'folder-exist',
      'Output folder does not exist or is not a folder entity',
      async function (v) {
        const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

        if (value) {
          const parentResponse = await getPathEntity(value.split('/')[0]);
          if (!(
            parentResponse.status === 200
              && parentResponse.data.data.type !== ENTITY_TYPE_FILE
          )
          ) {
            return this.createError({ message: 'Output parent folder does not exist' });
          }

          const valueResponse = await getPathEntity(value);

          return valueResponse.status === 200 && valueResponse.data.data.type !== ENTITY_TYPE_FILE;
        }

        return false;
      },
    ),
  comparingFilePath: yup.string().nullable()
    .test(
      'start_end_with_space',
      'The element of the comparing file path can not start or end with spaces',
      function (v) {
        const value = getCorrectPath(v, this.parent.comparingFilePathSearchValue);

        let result = true;
        value.split('/').forEach(path => {
          if (path.startsWith(' ') || path.endsWith(' ')) {
            result = false;
          }
        });

        return result;
      },
    )
    .test(
      'allowed-characters',
    <>
      Comparing file name can contain only the next characters:
      <br />
      {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
    </>,
    function (v) {
      const value = getCorrectPath(v, this.parent.comparingFilePathSearchValue);

      if (!(value === null || value === undefined) && !/^[a-zA-Z0-9\s/\-._]+$/.test(value)) return false;

      return true;
    },
    )
    .test(
      'not-new-lines',
      'Comparing file name cannot contain the new lines',
      function (v) {
        const { comparingFilePathSearchValue } = this.parent;
        const value = `${v}/${comparingFilePathSearchValue}`;

        if (!(value === null || value === undefined) && value.includes('\n')) return false;

        return true;
      },
    )
    .test(
      'not-file-exist',
      'Comparing file does not exist or is not a file entity',
      async function (v) {
        const { comparingFilePathSearchValue } = this.parent;
        const value = `${v}/${comparingFilePathSearchValue}`;

        if (!(value === null || value === undefined)) {
          const response = await getPathEntity(value);

          return response.status === 200 && response.data.data.type === ENTITY_TYPE_FILE;
        }

        return true;
      },
    ),
});

const generateInitValues = task => {
  const [comparingFilePath, comparingFileName] = parsePath(task.expectedResult || '');
  const [outputFolderPath, outputFilename] = parsePath(task.output || '');
  const [sourceFilePath, sourceFilename] = parsePath(task.source || '');
  const config = { ...(task.config ? JSON.parse(task.config) : {}) };

  return {
    conversionTag: task.tag || '',
    conversionType: task.converter || '',
    outputFilename: outputFilename || '',

    sourceFilePath: sourceFilePath || '',
    sourceFilePathSearchValue: sourceFilename || '',
    sourceFilePathObjects: [],

    outputFolderPath,
    outputFolderPathSearchValue: '',
    outputFolderPathObjects: [],

    comparingFilePath: comparingFilePath || '',
    comparingFilePathSearchValue: comparingFileName || '',
    comparingFilePathObjects: [],

    config: config.parameters || config || {},

    createFolders: false,
  };
};

function filterObjects(objects, searchValue) {
  return applySearch(objects, searchValue).sort((a, b) => b.type.localeCompare(a.type));
}

function CreateConversionDialog(props) {
  const {
    open,
    onClose,
    rerunTask,
    resetOnClose,
  } = props;

  const dispatch = useDispatch();

  const [conversionTags, setConversionTags] = useState([]);
  const [loadingConversionTags, setLoadingConversionTags] = useState(false);

  const [conversionTypes, setConversionTypes] = useState([]);
  const [loadingConversionTypes, setLoadingConversionTypes] = useState(false);

  const [loadingAntConfigParameters, setLoadingAntConfigParameters] = useState(false);
  const [antConfigParameters, setAntConfigParameters] = useState([]);

  const [creatingTask, setCreatingTask] = useState(false);

  const [pythonParams, setPythonParams] = useState({});
  const [loadingPythonParams, setLoadingPythonParams] = useState(false);

  const [additionalParameters, setAdditionalParameters] = useState({});

  const [checkingOutputFile, setCheckingOutputFile] = useState(false);
  const [outputFileWarning, setOutputFileWarning] = useState(undefined);

  const [loadingSourceFileObjects, setLoadingSourceFileObjects] = useState(false);
  const [loadingOutputFolderObjects, setLoadingOutputFolderObjects] = useState(false);
  const [loadingComparingFileObjects, setLoadingComparingFileObjects] = useState(false);

  const [openAddNewParameterDialog, setOpenAddNewParameterDialog] = useState(false);

  const [goToLastStep] = useState(Object.keys(rerunTask).length > 4);

  const formik = useFormik({
    initialValues: generateInitValues(rerunTask || {}),
    validationSchema,
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
  });

  let loading = false;

  if (goToLastStep) {
    loading = [
      checkingOutputFile,
      loadingPythonParams,
      loadingConversionTags,
      loadingConversionTypes,
      loadingSourceFileObjects,
      loadingAntConfigParameters,
      loadingOutputFolderObjects,
      loadingComparingFileObjects,
    ].some(v => v === true);
  }

  const [rerunConfig] = useState(rerunTask && rerunTask.config ? rerunTask.config : undefined);

  const handleClose = link => {
    onClose(link);

    setAntConfigParameters([]);
    setAdditionalParameters({});

    formik.resetForm();
  };

  const onFormSubmit = values => {
    const selectedConversionType = getSelectedConversionType();

    let outputFolder = `${values.outputFolderPath}${values.outputFolderPath.endsWith('/') ? '' : '/'}`;
    if (values.outputFolderPathSearchValue !== '') {
      outputFolder += `${values.outputFolderPathSearchValue}${values.outputFolderPathSearchValue.endsWith('/') ? '' : '/'}`;
    }

    const task = {
      source: `${values.sourceFilePath}${values.sourceFilePath.endsWith('/') ? '' : '/'}${values.sourceFilePathSearchValue}`,
      output: `${outputFolder}${values.outputFilename}`,
      engine: selectedConversionType.engine,
      converter: values.conversionType,
      tag: values.conversionTag,
    };

    if (values.comparingFilePath) {
      task.expected_result = `${values.comparingFilePath}${values.comparingFilePath.endsWith('/') ? '' : '/'}${values.comparingFilePathSearchValue}`;
    }

    if (selectedConversionType.engine === TASK_ENGINE_XSLT) {
      if (values.config && values.config && Object.keys(values.config).length > 0) {
        const parameters = {};

        Object.keys(additionalParameters).forEach(p => {
          if (values.config[p].value !== '') parameters[p] = values.config[p];
        });

        if (Object.keys(parameters).length > 0) task.config = JSON.stringify({ parameters });
      }
    }

    if (selectedConversionType.engine === TASK_ENGINE_PYTHON) {
      if (values.config && values.config && Object.keys(values.config).length > 0) {
        const config = {};

        if (Object.prototype.hasOwnProperty.call(pythonParams, 'fields')) {
          Object.keys(pythonParams.fields).forEach(key => {
            if (values.config[key].value !== '') config[key] = values.config[key];
          });
        }

        if (Object.prototype.hasOwnProperty.call(pythonParams, 'configurations')) {
          Object.keys(pythonParams.configurations).forEach(key => {
            if (values.config[key].value !== '') config[key] = values.config[key];
          });
        }

        if (Object.keys(config).length > 0) task.config = JSON.stringify(config);
      }
    }

    dispatch(createTask(task, values.env))
      .then(res => handleClose(`${TASKS_ROUTE}/${res.id}`))
      .catch(() => setCreatingTask(false));
  };

  const onCreateButtonClick = () => {
    if (creatingTask) return;
    setCreatingTask(true);

    onFormSubmit(formik.values);
  };

  useEffect(() => {
    if (open) {
      setLoadingSourceFileObjects(true);

      getObjectsFromPath(`/${formik.values.sourceFilePath}`)
        .then(objects => {
          formik.setFieldValue('sourceFilePathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('sourceFilePath').then(() => setLoadingSourceFileObjects(false));
            });
        });
    }
  }, [formik.values.sourceFilePath, open]);

  useEffect(() => {
    if (open) {
      setLoadingOutputFolderObjects(true);

      const value = getCorrectPath(formik.values.outputFolderPath, '');

      getObjectsFromPath(`/${value}`)
        .then(objects => {
          formik.setFieldValue('outputFolderPathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('outputFolderPath').then(() => setLoadingOutputFolderObjects(false));
            });
        });
    }
  }, [formik.values.outputFolderPath, open]);

  useEffect(() => {
    if (open) {
      setLoadingComparingFileObjects(true);

      getObjectsFromPath(`/${formik.values.comparingFilePath}`)
        .then(objects => {
          formik.setFieldValue('comparingFilePathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('comparingFilePath').then(() => setLoadingComparingFileObjects(false));
            });
        });
    }
  }, [formik.values.comparingFilePath, open]);

  function getSelectedConversionType() {
    return conversionTypes.find(v => v.name === formik.values.conversionType);
  }

  const clearPythonParams = params => {
    if (Object.prototype.hasOwnProperty.call(params, 'fields')) {
      Object.keys(params.fields).forEach(key => {
        delete formik.values.config[key];
      });
    }

    if (Object.prototype.hasOwnProperty.call(params, 'configurations')) {
      Object.keys(params.configurations).forEach(key => {
        delete formik.values.config[key];
      });
    }
  };

  const loadPythonParams = type => {
    setLoadingPythonParams(true);

    const selectedConversionType = type || getSelectedConversionType();

    if (selectedConversionType && selectedConversionType.engine === TASK_ENGINE_PYTHON && formik.values.conversionTag !== '') {
      const { name } = selectedConversionType;

      dispatch(
        readFile(`repo-python-converters-params/${name}/ryffine.converter-${formik.values.conversionTag}-params.json`),
      )
        .then(res => setPythonParams(oldParams => {
          clearPythonParams(oldParams);

          const config = JSON.parse(rerunConfig || '{}');

          const params = JSON.parse(res.data.data);
          if (Object.prototype.hasOwnProperty.call(params, 'fields')) {
            Object.keys(params.fields).forEach(key => {
              let defaultValue = '';
              if (params.fields[key].choices) defaultValue = [];

              formik.setFieldValue(
                `config.${key}`,
                config[key] || params.fields[key].default || defaultValue,
              );
            });
          }

          if (Object.prototype.hasOwnProperty.call(params, 'configurations')) {
            Object.keys(params.configurations).forEach(key => {
              formik.setFieldValue(
                `config.${key}`,
                config[key] || params.configurations[key].default,
              );
            });
          }

          return params;
        }))
        .catch(() => setPythonParams(oldParams => {
          clearPythonParams(oldParams);

          return [];
        }))
        .finally(() => setLoadingPythonParams(false));
    } else {
      setPythonParams(oldParams => {
        clearPythonParams(oldParams);

        return [];
      });
      setLoadingPythonParams(false);
    }
  };

  const loadAntParameters = type => {
    setLoadingAntConfigParameters(true);

    const selectedConversionType = type || getSelectedConversionType();

    if (selectedConversionType && selectedConversionType.engine === TASK_ENGINE_XSLT && formik.values.conversionTag !== '') {
      const { name } = selectedConversionType;

      dispatch(
        readFile(`repo-xsl-converters-params/${name}/release-${formik.values.conversionTag}-params.xml`),
      )
        .then(res => {
          const paramsNames = [];
          const parsedXml = xml2js(res.data.data, { compact: true });

          let parsedParams = [];
          if (Array.isArray(parsedXml.parameters.parameter)) {
            parsedParams = parsedXml.parameters.parameter;
          } else {
            parsedParams = [parsedXml.parameters.parameter];
          }

          const parameters = parsedParams.map(param => {
            if (paramsNames.includes(param._attributes.name)) return Promise.reject();

            return {
              required: param._attributes.required === 'yes',
              description: param._attributes.description,
              default: param._attributes.default,
              name: param._attributes.name,
              type: param._attributes.type,
            };
          });

          setAntConfigParameters(parameters);
        })
        .catch(() => {
          setAntConfigParameters([]);
        })
        .finally(() => setLoadingAntConfigParameters(false));
    } else {
      setAntConfigParameters([]);
      setLoadingAntConfigParameters(false);
    }
  };

  useEffect(() => {
    const params = {};
    formik.setFieldValue('config', {});

    if (antConfigParameters.length > 0) {
      antConfigParameters.forEach(param => {
        params[param.name] = {
          paramType: param.required ? 'required' : 'optional',
          description: param.description,
          type: param.type || 'text',
          required: param.required,
          default: param.default,
        };

        const value = formik.values.config[param.name]
          ? formik.values.config[param.name].value : undefined;

        formik.setFieldValue(
          `config.${param.name}`,
          { value: value || param.default || '', type: param.type || 'text' },
        );
      });
    }

    if (rerunConfig) {
      const config = JSON.parse(rerunConfig);

      if (config.parameters) {
        Object.keys(config.parameters).forEach(paramName => {
          if (!Object.keys(params).includes(paramName)) {
            params[paramName] = {
              type: config.parameters[paramName].type,
              paramType: 'custom',
            };

            formik.setFieldValue(
              `config.${paramName}`,
              {
                value: config.parameters[paramName].value || '',
                type: config.parameters[paramName].type || 'text',
              },
            );
          } else {
            formik.setFieldValue(`config.${paramName}.value`, config.parameters[paramName].value);
          }
        });
      }
    }

    setAdditionalParameters(params);
  }, [antConfigParameters]);

  useEffect(() => {
    if (open && formik.values.conversionType !== '') formik.validateField('conversionType');
  }, [formik.values.conversionType, open]);

  useEffect(() => {
    if (open && formik.values.outputFilename !== '') formik.validateField('outputFilename');
  }, [formik.values.outputFilename, open]);

  useEffect(() => {
    if (formik.values.conversionTag !== '') {
      formik.validateField('conversionTag').then(() => {
        loadAntParameters();
        loadPythonParams();
      });
    }
  }, [formik.values.conversionTag, open]);

  const onChangePathValue = (value, fieldName, searchFieldName) => {
    const split = value.split('/');

    const search = split[split.length - 1];
    const path = `${split.slice(0, -1).join('/')}`;

    if (path !== '/' || formik.values[fieldName] !== path) formik.setFieldValue(fieldName, path);
    formik.setFieldValue(searchFieldName, search).then(() => formik.validateField(fieldName));
  };

  let allowCreateFolders = false;
  if (formik.errors.outputFolderPath !== undefined) {
    if (formik.errors.outputFolderPath === 'Output folder does not exist or is not a folder entity') {
      allowCreateFolders = true;
    }
  }

  let outputFolder = `${formik.values.outputFolderPath}${formik.values.outputFolderPath.endsWith('/') ? '' : '/'}`;
  if (formik.values.outputFolderPathSearchValue !== '') {
    outputFolder += `${formik.values.outputFolderPathSearchValue}${formik.values.outputFolderPathSearchValue.endsWith('/') ? '' : '/'}`;
  }

  const fields = [];
  const configurations = [];

  let allowContinue = true;

  if (pythonParams) {
    if (Object.prototype.hasOwnProperty.call(pythonParams, 'fields')) {
      const errorFieldCheck = (key, value) => (value === undefined ? `The ${key.replaceAll('_', ' ')} could not be empty` : undefined);

      const errorListFieldCheck = (key, value) => (value.length === 0 ? `At least one of the ${key.replaceAll('_', ' ')} should be selected` : undefined);

      Object.keys(pythonParams.fields).forEach(key => {
        if (typeof formik.values.config[key] !== 'undefined' || formik.values.config[key] === '') {
          let field = {};

          if (pythonParams.fields[key].choices) {
            field = {
              tooltip: pythonParams.fields[key].tooltip,
              item: value => {
                if (typeof formik.values.config[key] !== 'undefined') {
                  if (pythonParams.fields[key].multiple) {
                    return (
                      <MenuItem key={value} value={value}>
                        <Checkbox
                          checked={formik.values.config[key].indexOf(value) > -1}
                          style={{ padding: '0 9px 0 0' }}
                        />
                        <ListItemText primary={value} />
                      </MenuItem>
                    );
                  }

                  return <MenuItem key={value} value={value}>{value}</MenuItem>;
                }

                return undefined;
              },
              changeValue: value => {
                setTimeout(
                  () => formik.setFieldValue(`config.${key}`, [...value]),
                  0,
                );
              },
              onChange: event => {
                const { value } = event.target;
                if (Array.isArray(value) && value[value.length - 1] === 'all') {
                  setTimeout(
                    () => {
                      const { choices } = pythonParams.fields[key];
                      const currentValue = formik.values.config[key];

                      let listValue = choices;
                      if (
                        currentValue.length === choices.length
                        || (currentValue.length !== 0 && currentValue.length !== choices.length)
                      ) {
                        listValue = [];
                      }

                      formik.setFieldValue(`config.${key}`, [...listValue]);
                    },
                    0,
                  );
                  return;
                }

                formik.handleChange(event);
              },
              items: pythonParams.fields[key].choices.sort(sortAlphaNum),
              enableSelectAll: pythonParams.fields[key].multiple,
              multiple: pythonParams.fields[key].multiple,
              value: formik.values.config[key],
              label: pythonParams.fields[key].label,
              renderValue: value => {
                if (value === '') {
                  return (
                    <Placeholder>{pythonParams.fields[key].label}</Placeholder>
                  );
                }

                if (Array.isArray(value)) return value.join(', ');
                return value;
              },
              error: Array.isArray(formik.values.config[key])
                ? errorListFieldCheck(key, formik.values.config[key])
                : errorFieldCheck(key, formik.values.config[key]),
              key: `config.${key}`,
            };
          } else {
            field = {
              tooltip: pythonParams.fields[key].tooltip,
              onChange: event => formik.handleChange(event),
              error: errorFieldCheck(key, formik.values.config[key]),
              value: formik.values.config[key],
              label: pythonParams.fields[key].label,
              key: `config.${key}`,
            };
          }

          if (field.error) allowContinue = false;

          fields.push(field);
        }
      });
    }

    if (Object.prototype.hasOwnProperty.call(pythonParams, 'configurations')) {
      Object.keys(pythonParams.configurations).forEach(key => {
        configurations.push({
          tooltip: pythonParams.configurations[key].tooltip,
          onChange: event => formik.handleChange(event),
          label: pythonParams.configurations[key].label,
          value: formik.values.config[key],
          key: `config.${key}`,
        });
      });
    }
  }

  const config = {
    title: 'Create Conversion',
    steps: [
      {
        type: STEP_FIELD_TYPE_MULTIPLE,
        title: 'Task details',
        fields: [
          {
            item: type => <MenuItem key={type.name} value={type.name}>{type.name}</MenuItem>,
            onChange: event => {
              formik.setFieldValue('conversionTag', '');
              formik.handleChange(event);
            },
            value: formik.values.conversionType,
            error: formik.errors.conversionType,
            label: 'Conversion type',
            items: conversionTypes,
            renderValue: value => {
              if (value === '') return <Placeholder>Conversion type</Placeholder>;

              return value;
            },
            key: 'conversionType',
          },
          {
            item: tag => (
              <MenuItem key={tag.name} value={tag.name}>
                <Typography>{tag.name}</Typography>
                <Typography className="ml-1" variant="caption" color="textSecondary">
                  {formatDateStr(tag.updated)}
                </Typography>
              </MenuItem>
            ),
            tooltip: SHOW_TAG_LAST_MODIFIED_DATE
              ? conversionTagTooltip(formik.values.conversionTag, conversionTags)
              : undefined,
            displayValue: SHOW_TAG_LAST_MODIFIED_DATE
              ? displayTagValue(formik.values.conversionTag, conversionTags)
              : undefined,
            onChange: event => formik.handleChange(event),
            value: formik.values.conversionTag,
            error: formik.errors.conversionTag,
            renderValue: value => {
              if (value === '') return <Placeholder>Release</Placeholder>;

              return value;
            },
            items: conversionTags,
            key: 'conversionTag',
            label: 'Release',
          },
          ...fields,
        ],
        allowContinue: formik.values.conversionType !== '' && formik.values.conversionTag !== '' && allowContinue,
        isValid: (formik.values.conversionType !== '' && formik.errors.conversionType === undefined)
          && (formik.values.conversionTag !== '' && formik.errors.conversionTag === undefined),
        onSubmit: () => Promise.resolve(true),
        loading: false,
        configurations,
      },
      {
        title: 'Source file path',
        type: STEP_FIELD_TYPE_PATH,
        pathField: {
          objects: filterObjects(
            formik.values.sourceFilePathObjects, formik.values.sourceFilePathSearchValue,
          ),
          onChange: value => onChangePathValue(value, 'sourceFilePath', 'sourceFilePathSearchValue'),
          value: formik.values.sourceFilePath !== ''
            ? `${formik.values.sourceFilePath}${formik.values.sourceFilePath.endsWith('/') ? '' : '/'}${formik.values.sourceFilePathSearchValue}`
            : formik.values.sourceFilePathSearchValue,
          error: formik.errors.sourceFilePath,
          loading: false,
        },
        allowContinue: formik.values.sourceFilePath !== '',
        isValid: formik.errors.sourceFilePath === undefined,
        onSubmit: () => Promise.resolve(true),
        loading: loadingSourceFileObjects,
      },
      {
        title: 'Output file path',
        type: STEP_FIELD_TYPE_PATH,
        displayValue: `${outputFolder}${formik.values.outputFilename}`,
        displayNode: (
          <span
            dangerouslySetInnerHTML={{__html: `${outputFolder.replaceAll(' ', '&nbsp;').replaceAll('/', ' / ')}<b>${formik.values.outputFilename.replaceAll(' ', '&nbsp;')}</b>`}} // eslint-disable-line
          />
        ),
        fields: [
          {
            onChange: event => formik.handleChange(event),
            value: formik.values.outputFilename,
            error: formik.errors.outputFilename,
            placeholder: 'Example.zip',
            label: 'Output file name',
            key: 'outputFilename',
          },
        ],
        pathField: {
          label: 'Output folder path',
          placeholder: 'Search a folder by name',
          objects: filterObjects(
            formik.values.outputFolderPathObjects, formik.values.outputFolderPathSearchValue,
          ).filter(o => o.type === 'folder'),
          onChange: value => {
            onChangePathValue(value, 'outputFolderPath', 'outputFolderPathSearchValue');
            formik.setFieldValue('createFolders', false);
          },
          value: formik.values.outputFolderPath !== ''
            ? `${formik.values.outputFolderPath}${formik.values.outputFolderPath.endsWith('/') ? '' : '/'}${formik.values.outputFolderPathSearchValue}`
            : formik.values.outputFolderPathSearchValue,
        },
        configurations: allowCreateFolders ? [
          {
            tooltip: (
              <p>
                <p>
                  The output folder does not exist. If you select this parameter,
                  the folder will be created automatically.
                </p>
                <p>
                  <b>Notice. </b>
                  Use this parameter carefully.
                </p>
                <p>
                  THE PARENT FOLDER MUST EXIST.
                </p>
              </p>
            ),
            onChange: event => formik.handleChange(event),
            value: formik.values.createFolders,
            label: 'Create Folders',
            key: 'createFolders',
          },
        ] : [],
        allowContinue: formik.values.outputFolderPath !== ''
          && (formik.errors.outputFolderPath === undefined || formik.values.createFolders)
          && formik.values.outputFilename !== '',
        isValid: formik.values.outputFolderPath !== ''
          && (formik.values.outputFilename !== '' && formik.errors.outputFilename === undefined),
        onSubmit: () => Promise.resolve(true),
        error: formik.errors.outputFolderPath,
        loading: loadingOutputFolderObjects,
        warning: outputFileWarning,
      },
      {
        title: 'Comparing file (optional)',
        type: STEP_FIELD_TYPE_PATH,
        pathField: {
          objects: filterObjects(
            formik.values.comparingFilePathObjects, formik.values.comparingFilePathSearchValue,
          ),
          onChange: value => onChangePathValue(value, 'comparingFilePath', 'comparingFilePathSearchValue'),
          value: formik.values.comparingFilePath !== ''
            ? `${formik.values.comparingFilePath}${formik.values.comparingFilePath.endsWith('/') ? '' : '/'}${formik.values.comparingFilePathSearchValue}`
            : formik.values.comparingFilePathSearchValue,
          error: formik.errors.comparingFilePath,
        },
        isValid: (formik.values.comparingFilePathSearchValue !== '' || formik.values.comparingFilePath !== '')
          ? formik.errors.comparingFilePath === undefined : true,
        onSubmit: () => Promise.resolve(true),
        loading: loadingComparingFileObjects,
        allowContinue: true,
      },
    ],
    onSubmit: () => onCreateButtonClick(),
    seeValuesAfterActiveStep: true,
    allowAnyStepSelection: true,
    loading: creatingTask,
    initActiveStep: 0,
  };

  useEffect(() => {
    setCheckingOutputFile(true);

    const fileId = `${outputFolder}${formik.values.outputFilename}`;

    if (formik.values.outputFolderPath === outputFolder) {
      const exist = formik.values.outputFolderPathObjects.some(file => file.id === fileId);

      if (exist) setOutputFileWarning('The output file already exists');
      else setOutputFileWarning(undefined);

      setCheckingOutputFile(false);
    } else {
      dispatch(checkFileExist(fileId))
        .then(() => setOutputFileWarning('The output file already exists'))
        .catch(() => setOutputFileWarning(undefined))
        .finally(() => setCheckingOutputFile(false));
    }
  }, [
    formik.values.outputFolderPath,
    formik.values.outputFolderPathSearchValue,
    formik.values.outputFolderPathObjects,
    formik.values.outputFilename,
  ]);

  const validateParameter = f => {
    if (f.required && formik.values.config[f.name].value === '') return `The '${f.name}' is required`;

    return undefined;
  };

  const buildHelpAntParameterText = name => {
    const param = additionalParameters[name];

    if (param.description || param.required || param.default) {
      return (
        <>
          {param.description
          && (
          <>
            <p><b>Description</b></p>
            <p>{param.description}</p>
          </>
          )}
          {param.required
          && (
          <>
            <p><b>Required</b></p>
            <p>{param.required ? 'Yes' : 'No'}</p>
          </>
          )}
          {param.default
          && (
          <>
            <p><b>Default</b></p>
            <p>{param.default}</p>
          </>
          )}
        </>
      );
    }

    return undefined;
  };

  const onFileParameterClear = name => formik.setFieldValue(`config.${name}.value`, '');

  const onCustomParameterDelete = name => {
    delete formik.values.config[name];
    delete additionalParameters[name];

    setAdditionalParameters({ ...additionalParameters });
  };

  const conversion = getSelectedConversionType();
  if (conversion && conversion.engine === TASK_ENGINE_XSLT) {
    const parameters = Object.entries(additionalParameters)
      .map(([key, value]) => Object.assign(value, { name: key }));

    config.steps.splice(1, 0, {
      isValid: !parameters
        .filter(f => f.required).map(f => formik.values.config[f.name].value)
        .some(v => v === ''),
      fields: parameters.sort((a, b) => b.required - a.required)
        .filter(f => formik.values.config[f.name])
        .map(f => ({
          onClear: f.type === 'file' ? () => onFileParameterClear(f.name) : undefined,
          onDelete: f.paramType === 'custom' ? () => onCustomParameterDelete(f.name) : undefined,
          label: `${f.name}${f.required ? ' *' : ''}`,
          onChange: value => {
            if (value.target) formik.handleChange(value);
            else formik.setFieldValue(`config.${f.name}.value`, value);
          },
          tooltip: buildHelpAntParameterText(f.name),
          value: formik.values.config[f.name].value,
          type: formik.values.config[f.name].type,
          tooltipOnClear: 'Clear parameter value',
          tooltipOnDelete: 'Remove parameter',
          key: `config.${f.name}.value`,
          error: validateParameter(f),
          placeholder: 'Value',
        })),
      additionalButton: (
        <Button
          onClick={() => setOpenAddNewParameterDialog(true)}
          className="button"
          color="primary"
        >
          <IconButton className="outlined-button-icon"><MdOutlineAddCircle /></IconButton>
          Add a parameter
        </Button>
      ),
      onSubmit: () => Promise.resolve(true),
      type: STEP_FIELD_TYPE_MULTIPLE,
      title: 'Parameters',
      allowContinue: true,
      loading: false,
    });
  }

  if (goToLastStep) config.initActiveStep = MAX_NUMBER_OF_STEPS;

  useEffect(() => {
    if (open) {
      setLoadingConversionTypes(true);

      dispatch(fetchConversionTypes())
        .then(types => {
          setConversionTypes(types.sort((a, b) => ((a.name > b.name) ? 1 : -1)));

          if (rerunTask) {
            if (formik.values.conversionType !== '') {
              const type = types.find(v => v.name === formik.values.conversionType);

              if (type.engine === TASK_ENGINE_XSLT) loadAntParameters(type);
              if (type.engine === TASK_ENGINE_PYTHON) loadPythonParams(type);
            }
          }
        })
        .then(() => setLoadingConversionTypes(false));
    }
  }, [dispatch, open]);

  useEffect(() => {
    if (formik.values.conversionType) {
      setConversionTags([]);

      const selectedConversionType = getSelectedConversionType();
      const engine = (selectedConversionType && selectedConversionType.engine) || rerunTask.engine;
      const converter = (selectedConversionType && selectedConversionType.name)
        || rerunTask.converter;

      setLoadingConversionTags(true);

      dispatch(fetchConversionTags(engine, converter))
        .then(tags => setConversionTags(tags.sort((a, b) => -a.updated.localeCompare(b.updated))))
        .then(() => setLoadingConversionTags(false));
    }
  }, [dispatch, formik.values.conversionType]); // esint-disable-line

  const handleAddNewParameter = (name, type, defaultValue) => {
    formik.setFieldValue(`config.${name}`, { value: defaultValue || '', type });

    setAdditionalParameters(
      Object.assign(
        additionalParameters,
        { [name]: { type, paramType: 'custom' } },
      ),
    );
  };

  return (
    <>
      <CreateTaskDialog
        open={open}
        config={config}
        loading={loading}
        onClose={() => {
          onClose();

          if (resetOnClose) {
            setAntConfigParameters([]);
            setAdditionalParameters({});
            formik.setValues(generateInitValues({}));
          }
        }}
        resetOnClose={resetOnClose}
      />

      <AddParameterDialog
        open={openAddNewParameterDialog}
        onClose={() => setOpenAddNewParameterDialog(false)}
        alreadyCreatedParams={Object.keys(formik.values.config)}
        onSubmit={val => handleAddNewParameter(val.name, val.type, val.default)}
      />
    </>
  );
}

CreateConversionDialog.defaultProps = { rerunTask: {}, resetOnClose: true };

CreateConversionDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  resetOnClose: PropTypes.bool,
  rerunTask: PropTypes.object,
};

export default CreateConversionDialog;

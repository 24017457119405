import React from 'react';
import { Typography } from '@material-ui/core';

function Evaluations() {
  return (
    <div>
      <Typography variant="h5">
        Evaluations page is coming soon.
      </Typography>
    </div>
  );
}

export default Evaluations;

import React, { useState, useEffect } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import {
  IconButton,
  Typography,
  Button,
  Grid,
  Link,
  makeStyles,
} from '@material-ui/core';
import { HiStop } from 'react-icons/hi';
import { MdRefresh } from 'react-icons/md';
import { FaExclamationCircle } from 'react-icons/fa';
import {
  fetchTask, stopTask, getTaskProgress, fetchConversionTags,
} from '../../redux/actions/taskActions';
import {
  TextValue, getHumanReadebleTimeDiff,
} from '../common/Utility';
import { formatDateStr } from '../../core/services/commonService';
import { checkFileExist } from '../../redux/actions/fileStorageActions';
import { showSuccessSnackbar } from '../../redux/actions/appActions';
import DownloadLink from '../common/DownloadLink';
import {
  TASK_STATUS_FINISHED,
  TASK_STATUS_STOPPED,
  TASK_STATUS_INTERRUPTED,
  TASK_STATUS_DEPROVISIONING,
  TASK_CONVERTER_NORMALIZATION,
  TASK_ENGINE_PANDOC,
  TaskEntity,
  TASK_ENGINE_INTEGRATOR,
} from '../../core/entities';
import { FILE_STORAGE_ROUTE, CREATE_TASK_ROUTE } from '../../core/constants';
import CustomCircularProgress from '../common/CustomCircularProgress';
import ConfirmButtonDialog from '../common/ConfirmButton';
import Loader from '../common/Loader';
import { parseFileEntity } from '../../core/parsers';
import {
  ENABLE_OLD_CREATE_CONVERSION,
  SHOW_TAG_LAST_MODIFIED_DATE,
  SECONDARY_UI_COLOR,
} from '../../config';
import CreateConversionDialog from './CreateConversionDialog';
import CreateNormalizationDialog from './CreateNormalizationDialog';
import CreatePandocDialog from './CreatePandocDialog';
import CreatePaligoDialog from './CreatePaligoDialog';
import CreateIxiasoftDialog from './CreateIxiasoftDialog';
import CreateSdlDialog from './CreateSdlDialog';
import { displayTagValue } from '../../core/services/taskConversionService';
import CreateFluidTopicsDialog from './CreateFluidTopicsDialog';
import CreateGitHubDialog from './CreateGitHubDialog';
import CreateKionSdlDialog from './CreateKionSdlDialog';

const ALREADY_STOPPED_TASK_STATUSES = [
  TASK_STATUS_STOPPED, TASK_STATUS_INTERRUPTED, TASK_STATUS_DEPROVISIONING, TASK_STATUS_FINISHED,
];

const useStyles = makeStyles(() => ({
  warningContainer: {
    color: '#BC211D',
  },
  typography: {
    fontWeight: 500,
  },
}));

function TaskDetailsInfo({ task, hasWarnings, hasErrors }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [progress, setProgress] = useState(null);

  const [fileExist, setFileExist] = useState(false);
  const [checkingFileExist, setCheckingFileExist] = useState(false);

  const [outputFileWarning, setOutputFileWarning] = useState('');

  const [pageLoading, setPageLoading] = useState(true);

  const [openSdlTaskDialog, setOpenSdlTaskDialog] = useState(false);
  const [openKionSdlTaskDialog, setOpenKionSdlTaskDialog] = useState(false);

  const [openCreateTaskDialog, setOpenCreateTaskDialog] = useState(false);
  const [openPandocTaskDialog, setOpenPandocTaskDialog] = useState(false);
  const [openPaligoTaskDialog, setOpenPaligoTaskDialog] = useState(false);
  const [openGitHubTaskDialog, setOpenGitHubTaskDialog] = useState(false);
  const [openIxiasoftTaskDialog, setOpenIxiasoftTaskDialog] = useState(false);
  const [openFluidTopicsTaskDialog, setOpenFluidTopicsTaskDialog] = useState(false);
  const [openNormalizationTaskDialog, setOpenNormalizationTaskDialog] = useState(false);

  const [conversionTags, setConversionTags] = useState([]);

  useEffect(() => {
    if (SHOW_TAG_LAST_MODIFIED_DATE && !['', 'latest', 'default'].includes(task.tag)) {
      dispatch(fetchConversionTags(task.engine, task.converter))
        .then(tags => setConversionTags(tags.sort((a, b) => -a.updated.localeCompare(b.updated))))
        .then(() => setPageLoading(false));
    } else setPageLoading(false);
  }, [dispatch]);

  useEffect(() => {
    let mounted = true;

    if (task.status === TASK_STATUS_FINISHED) {
      setCheckingFileExist(true);

      dispatch(checkFileExist(task.output))
        .then(res => {
          if (mounted) {
            setFileExist(true);

            const file = parseFileEntity(res.data.data);
            if (new Date(file.updated).getTime() > new Date(task.finished).getTime()) {
              setOutputFileWarning('File has been replaced by other task');
            }
          }
        })
        .catch(() => {
          if (mounted) {
            setFileExist(false);
          }
        })
        .finally(() => setCheckingFileExist(false));
    }

    return () => { mounted = false; };
  }, [task.output, task.status, dispatch, setFileExist]);

  useEffect(() => {
    if (task.status === 'RUNNING') {
      getTaskProgress(task.id)
        .then(newProgress => {
          if (JSON.stringify(progress) !== JSON.stringify(newProgress)) {
            setProgress(newProgress);
          }
        });
    } else {
      setProgress(null);
    }
  }, [task]);

  if (pageLoading || checkingFileExist) return <Loader />;

  const config = JSON.parse(task.config || '{}');

  let progressMessage = '';
  if (progress && progress.message) {
    const div = document.createElement('div');
    div.innerHTML = progress.message;

    progressMessage = div.innerText;
  }

  const items = [
    { label: 'Conversion type', node: <TextValue>{task.converter}</TextValue> },
    {
      label: 'Release',
      node: (
        <TextValue>
          {!SHOW_TAG_LAST_MODIFIED_DATE && task.tag}

          {SHOW_TAG_LAST_MODIFIED_DATE && (displayTagValue(task.tag, conversionTags) || task.tag)}
        </TextValue>
      ),
      hidden: ['', 'latest', 'default'].includes(task.tag),
    },
    {
      label: config.data_type === 'folder' ? 'Source folder path' : 'Source file path',
      node: (
        <TextValue variantMapping={{ body1: 'div' }} style={{ whiteSpace: 'normal', width: '100%', overflowWrap: 'break-word' }}>
          {config.data_type === 'folder' && (
            <Link to={`${FILE_STORAGE_ROUTE}/${task.source}`} component={RouterLink}>
              <span
                dangerouslySetInnerHTML={{ __html: (task.source || '').replaceAll(' ', '&nbsp;').replaceAll('/', ' / ') }}  // eslint-disable-line
              />
            </Link>
          )}

          {config.data_type !== 'folder' && (
            <DownloadLink
              displayValue={(task.source || '').replaceAll(' ', '&nbsp;').replaceAll('/', ' / ')}
              filepath={task.source}
            />
          )}
        </TextValue>
      ),
      hidden: task.source === 'N/A',
    },
    {
      label: 'Output file path',
      node: (
        <TextValue variantMapping={{ body1: 'div' }} style={{ whiteSpace: 'normal', width: '100%', overflowWrap: 'break-word' }}>
          {task.status === TASK_STATUS_FINISHED && fileExist
            ? <DownloadLink filepath={task.output} displayValue={(task.output || '').replaceAll(' ', '&nbsp;').replaceAll('/', ' / ')} />
            : (
              <span
                dangerouslySetInnerHTML={{__html: (task.output || '').replaceAll(' ', '&nbsp;').replaceAll('/', ' / ')}}  // eslint-disable-line
              />
            )}
        </TextValue>
      ),
      error: outputFileWarning,
      hidden: task.output === '',
    },
    {
      label: 'Comparing file',
      hidden: !task.expectedResult,
      node: (
        <TextValue variantMapping={{ body1: 'div' }} style={{ whiteSpace: 'pre' }}>
          <DownloadLink filepath={task.expectedResult} displayValue={(task.expectedResult || '').replaceAll(' ', '&nbsp;').replaceAll('/', ' / ')} />
        </TextValue>
      ),
    },
    // {label: "CPU", node: <TextValue>{task.ram}</TextValue>},
    // {label: "RAM", node: <TextValue>{task.cpu}</TextValue>},
    // {label: "Execution time", node: <TextValue>TODO</TextValue>},
    {
      label: 'Date created',
      hidden: !task.created,
      node: (
        <TextValue>{formatDateStr(task.created)}</TextValue>
      ),
    },
    {
      label: 'Start date',
      hidden: !task.started,
      node: (
        <TextValue>{formatDateStr(task.started)}</TextValue>
      ),
    },
    {
      label: 'Finish date',
      hidden: !task.finished,
      node: (
        <TextValue>{formatDateStr(task.finished)}</TextValue>
      ),
    },
    {
      label: 'Duration',
      hidden: !task.finished,
      node: (
        <TextValue>{getHumanReadebleTimeDiff(task.finished, task.created)}</TextValue>
      ),
    },
    { label: 'Status', node: <TextValue>{capitalize(task.status)}</TextValue> },
    {
      label: '',
      hidden: !(hasErrors || hasWarnings),
      node: (
        <TextValue style={{ color: SECONDARY_UI_COLOR }}>
          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            <FaExclamationCircle className="mr-2" color={SECONDARY_UI_COLOR} size="16" />
            <span style={{ paddingTop: '2px' }}>
              This run has errors/warnings. Please check the Errors/Warnings tab.
            </span>
          </div>
        </TextValue>
      ),
    },
    {
      label: 'Progress',
      hidden: progress === null,
      node: (
        progress
        && (
          <TextValue style={{ display: 'inline-flex', alignItems: 'center' }}>
            <CustomCircularProgress value={progress.progress} />
            <TextValue className="ml-2">
              {progressMessage}
            </TextValue>
          </TextValue>
        )
      ),
    },
    { label: 'Owner', node: <TextValue>{task.owner}</TextValue> },
    {
      label: 'Environment',
      hidden: Object.keys(task.env || {}).length === 0,
      node: (
        <TextValue>{JSON.stringify(task.env)}</TextValue>
      ),
    },
  ];

  if (task.config) {
    items.push({
      label: 'Config',
      node: <TextValue style={{ fontSize: '12px' }}><pre>{JSON.stringify(JSON.parse(task.config), null, 2)}</pre></TextValue>,
    });
  }

  function buttonStopHandler() {
    dispatch(stopTask(task.id))
      .then(res => dispatch(showSuccessSnackbar(`Task ${res.id} has been stopped`)))
      .then(() => dispatch(fetchTask(task.id)));
  }

  const handleRerun = () => {
    if (ENABLE_OLD_CREATE_CONVERSION && !(
      task.converter.startsWith('paligo-')
      || task.converter.startsWith('sdl-')
      || task.converter.startsWith('ixiasoft-')
      || task.converter === TASK_CONVERTER_NORMALIZATION
      || task.engine === TASK_ENGINE_PANDOC
    )) {
      history.push(CREATE_TASK_ROUTE, { task });
      return;
    }

    if (task.engine === TASK_ENGINE_PANDOC) {
      setOpenPandocTaskDialog(true);
    } else if (task.converter === TASK_CONVERTER_NORMALIZATION) {
      setOpenNormalizationTaskDialog(true);
    } else if (['paligo-publish', 'paligo-import'].includes(task.converter)) {
      setOpenPaligoTaskDialog(true);
    } else if (['ixiasoft-import', 'ixiasoft-export'].includes(task.converter)) {
      setOpenIxiasoftTaskDialog(true);
    } else if (['sdl-import', 'sdl-export'].includes(task.converter)) {
      setOpenSdlTaskDialog(true);
    } else if (['kion-sdl-import', 'kion-sdl-export'].includes(task.converter)) {
      setOpenKionSdlTaskDialog(true);
    } else if (['github-upload', 'github-download'].includes(task.converter)) {
      setOpenGitHubTaskDialog(true);
    } else if (task.converter.startsWith('fluid-topics-')) {
      setOpenFluidTopicsTaskDialog(true);
    } else {
      setOpenCreateTaskDialog(true);
    }
  };

  const handleCreationForm = (changeValueMethod, link) => {
    changeValueMethod(false);

    if (link) history.push(link, {});
  };

  return (
    <>
      <Grid
        container
        style={{
          border: '1px solid rgba(51, 51, 51, 0.3)',
          borderRadius: '8px',
          background: 'white',
          marginTop: '32px',
          padding: '24px',
        }}
      >
        <Grid container item spacing={2}>
          {items.filter(item => !item.hidden).map(item => (
            <>
              <Grid item xs={6} sm={3} md={2} alignItems="center" justifyContent="flex-start">
                <Typography className={classes.typography}>{item.label}</Typography>
              </Grid>
              <Grid item xs={6} sm={9} md={10} alignItems="center">
                {item.node}
              </Grid>
              {item.error && (
                <>
                  <Grid item xs={6} sm={3} md={2} />
                  <Grid item xs={6} sm={9} md={10} alignItems="center" className={classes.warningContainer}>
                    <TextValue style={{ color: SECONDARY_UI_COLOR }}>
                      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        <FaExclamationCircle className="mr-2" color={SECONDARY_UI_COLOR} size="16" />
                        <span style={{ paddingTop: '2px' }}>{item.error}</span>
                      </div>
                    </TextValue>
                  </Grid>
                </>
              )}
            </>
          ))}
        </Grid>
      </Grid>

      <Grid className="mt-1" container spacing={4}>
        <Grid item xs={6} container justifyContent="flex-start">
          <Button
            onClick={() => handleRerun()}
            className="button"
            variant="outlined"
            color="primary"
          >
            <IconButton className="outlined-button-icon"><MdRefresh size={18} /></IconButton>
            Rerun Task
          </Button>
          {!ALREADY_STOPPED_TASK_STATUSES.includes(task.status)
            && (
            <ConfirmButtonDialog
              buttonText={(
                <>
                  <HiStop className="outlined-button-icon" color={SECONDARY_UI_COLOR} />
                  Stop
                </>
              )}
              buttonProps={{
                className: 'button ml-2', variant: 'outlined', color: 'secondary',
              }}
              confirmTitle="Confirm Stop"
              confirmText="Please confirm the task interruption"
              onConfirm={() => buttonStopHandler()}
            />
            )}
        </Grid>
      </Grid>

      {openCreateTaskDialog && (
        <CreateConversionDialog
          rerunTask={
            task
            && (
              task.converter !== TASK_CONVERTER_NORMALIZATION
              && task.engine !== TASK_ENGINE_INTEGRATOR
              && task.engine !== TASK_ENGINE_PANDOC
            )
              ? task : {}
          }
          onClose={link => handleCreationForm(setOpenCreateTaskDialog, link)}
          open={openCreateTaskDialog}
          resetOnClose={false}
        />
      )}

      {openNormalizationTaskDialog && (
        <CreateNormalizationDialog
          rerunTask={
            task && task.converter === TASK_CONVERTER_NORMALIZATION ? task : {}
          }
          onClose={link => handleCreationForm(setOpenNormalizationTaskDialog, link)}
          open={openNormalizationTaskDialog}
          resetOnClose={false}
        />
      )}

      {openPandocTaskDialog && (
        <CreatePandocDialog
          rerunTask={
            task && task.engine === TASK_ENGINE_PANDOC ? task : {}
          }
          onClose={link => handleCreationForm(setOpenPandocTaskDialog, link)}
          open={openPandocTaskDialog}
          resetOnClose={false}
        />
      )}

      {openPaligoTaskDialog && (
        <CreatePaligoDialog
          rerunTask={
            task
            && task.engine === TASK_ENGINE_INTEGRATOR
            && task.converter.startsWith('paligo')
              ? task : {}
          }
          onClose={link => handleCreationForm(setOpenPaligoTaskDialog, link)}
          open={openPaligoTaskDialog}
          resetOnClose={false}
        />
      )}

      {openIxiasoftTaskDialog && (
        <CreateIxiasoftDialog
          rerunTask={
            task
            && task.engine === TASK_ENGINE_INTEGRATOR
            && task.converter.startsWith('ixiasoft')
              ? task : {}
          }
          onClose={link => handleCreationForm(setOpenIxiasoftTaskDialog, link)}
          open={openIxiasoftTaskDialog}
          resetOnClose={false}
        />
      )}

      {openSdlTaskDialog && (
        <CreateSdlDialog
          rerunTask={
            task
            && task.engine === TASK_ENGINE_INTEGRATOR
            && task.converter.startsWith('sdl')
              ? task : {}
          }
          onClose={link => handleCreationForm(setOpenSdlTaskDialog, link)}
          open={openSdlTaskDialog}
        />
      )}

      {openFluidTopicsTaskDialog && (
        <CreateFluidTopicsDialog
          rerunTask={
            task
            && task.engine === TASK_ENGINE_INTEGRATOR
            && task.converter.startsWith('fluid-topics')
              ? task : {}
          }
          onClose={link => handleCreationForm(setOpenFluidTopicsTaskDialog, link)}
          open={openFluidTopicsTaskDialog}
        />
      )}

      {openGitHubTaskDialog && (
        <CreateGitHubDialog
          rerunTask={
            task
            && task.engine === TASK_ENGINE_INTEGRATOR
            && task.converter.startsWith('github')
              ? task : {}
          }
          onClose={link => handleCreationForm(setOpenGitHubTaskDialog, link)}
          open={openGitHubTaskDialog}
        />
      )}

      {openKionSdlTaskDialog && (
        <CreateKionSdlDialog
          rerunTask={
            task
            && task.engine === TASK_ENGINE_INTEGRATOR
            && task.converter.startsWith('kion-sdl')
              ? task : {}
          }
          onClose={link => handleCreationForm(setOpenKionSdlTaskDialog, link)}
          open={openKionSdlTaskDialog}
        />
      )}
    </>
  );
}

TaskDetailsInfo.defaultProps = { hasWarnings: false, hasErrors: false };

TaskDetailsInfo.propTypes = {
  task: PropTypes.oneOfType([
    PropTypes.instanceOf(TaskEntity),
    PropTypes.exact({}),
  ]).isRequired,
  hasWarnings: PropTypes.bool,
  hasErrors: PropTypes.bool,
};

export default TaskDetailsInfo;

import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
  Typography,
  IconButton,
  Box,
  InputAdornment,
  TextField,
  Checkbox,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { BiPlus } from 'react-icons/bi';
import { MdDeleteForever, MdUpload } from 'react-icons/md';
import { AiOutlineMenu } from 'react-icons/ai';
import { HiOutlineSearch, HiOutlineArrowLeft } from 'react-icons/hi';
import { useSearch } from '../../core/hooks';
import { deletePathObject, fetchObjectsFromPath } from '../../redux/actions/fileStorageActions';
import RyffineTable from '../common/RyffineTable';
import CreateFolderDialog from './CreateFolderDialog';
import { applySearch, generateTableRows, FS_TABLE_COLUMNS } from '../../core/services/fileStorageService';
import { is404, isObjectEmpty } from '../../core/services/commonService';
import FileDetails from './FileDetails';
import { ACTION_TYPE_NONE, FILE_STORAGE_ROUTE } from '../../core/constants';
import NotFound from '../common/NotFound';
import UploadFilesDialog from './UploadFilesDialog';
import { MENU_CLOSE, MENU_OPEN } from '../../redux/actionTypes';
import Loader from '../common/Loader';
import { MAIN_UI_COLOR, SECONDARY_UI_COLOR } from '../../config';
import ConfirmButtonV2Dialog from '../common/ConfirmButtonV2';
import { showErrorSnackbar } from '../../redux/actions/appActions';
import BulkDeleteDialog from './BulkDeleteDialog';

function FileStorage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [deleting, setDeleting] = useState(false);
  const [uploadFileDialogOpen, setUploadFileDialogOpen] = useState(false);
  const [createFolderDialogOpen, setCreateFolderDialogOpen] = useState(false);

  const [searchValue, setSearchValue] = useSearch();
  const {
    objects, file, errors, loading,
  } = useSelector(state => state.fs);

  const path = useLocation().pathname.substr(FILE_STORAGE_ROUTE.length);

  const isMenuOpen = useSelector(state => state.app.isMenuOpen);
  const isMobile = useSelector(state => state.app.isMobile);

  const { configuration } = useSelector(state => state.fs);

  const [changedPath, setChangedPath] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const [openBulkDeleteDialog, setOpenBulkDeleteDialog] = useState(false);

  const protectedFolders = configuration.obj.protectedFolders || [];

  useEffect(() => {
    dispatch(fetchObjectsFromPath(path)).then(() => {
      setChangedPath(false);
      setSelectedIds([]);
    });
  }, [dispatch, path]);

  const filteredObjects = useMemo(
    () => applySearch(objects, searchValue),
    [objects, searchValue],
  );

  const tableRows = useMemo(
    () => {
      const onNameClick = pathEntity => {
        setChangedPath(true);
        setSearchValue('');

        history.push(`${FILE_STORAGE_ROUTE}/${pathEntity.id}/`);
      };

      return generateTableRows(filteredObjects, onNameClick, () => true);
    },
    [filteredObjects, history, setSearchValue],
  );

  if (changedPath || loading || deleting || configuration.loading) {
    return <Loader />;
  }

  if (is404(errors)) {
    return <NotFound />;
  }

  if (!isObjectEmpty(file)) {
    return <FileDetails file={file} />;
  }

  const handleObjectSelection = id => {
    const values = [...selectedIds];

    if (values.indexOf(id) === -1) values.push(id);
    else values.splice(values.indexOf(id), 1);

    setSelectedIds(values);
  };

  let tableColumns = FS_TABLE_COLUMNS;
  if (tableRows.length > 0 && configuration.obj.allowsToDelete) {
    const buttonDisabled = selectedIds.length === 0;

    tableColumns = [
      ...FS_TABLE_COLUMNS,
      {
        field: 'action',
        headerName: (
          <ConfirmButtonV2Dialog
            buttonProps={{
              style: { padding: 0, color: buttonDisabled ? MAIN_UI_COLOR : SECONDARY_UI_COLOR },
              disabled: buttonDisabled,
            }}
            buttonText={<MdDeleteForever />}
            confirmText={(
              <Grid>
                <Typography>
                  Permanently delete selected file(s) and / or folder(s)?
                </Typography>
                <List>
                  {selectedIds.length > 0 && selectedIds.map(item => (
                    <ListItem style={{ padding: 0 }}>
                      <ListItemText primary={`- ${item}`} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}
            onConfirm={() => setOpenBulkDeleteDialog(true)}
            confirmButtonText="Remove"
            iconButton
          />
        ),
        sortable: false,
      },
    ];

    tableRows.forEach(row => {
      const item = row;
      const rowPath = row.id;
      const value = selectedIds.indexOf(rowPath) >= 0;

      item.action = {
        value,
        displayValue: (
          <Checkbox
            disabled={protectedFolders.indexOf(rowPath.split('/')[0]) > -1}
            onClick={() => handleObjectSelection(rowPath)}
            style={{ padding: 0 }}
            checked={value}
          />
        ),
      };
    });
  }

  let currentPath = path;
  if (currentPath.endsWith('/')) currentPath = currentPath.slice(0, -1);

  const backPath = `${FILE_STORAGE_ROUTE}${currentPath.substring(0, currentPath.lastIndexOf('/'))}/`;

  const onConfirmDelete = () => {
    setDeleting(true);

    deletePathObject(path.startsWith('/') ? path.slice(1) : path).then(() => history.push(backPath))
      .catch(err => dispatch(showErrorSnackbar(err.data.error)))
      .finally(() => setDeleting(false));
  };

  return (
    // TODO: remove style
    <>
      <div style={{
        borderBottom: '1px solid rgba(51, 51, 51, 0.3)',
        paddingBottom: '30px',
        paddingRight: '60px',
        paddingLeft: '60px',
        paddingTop: '48px',
        background: 'white',
      }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h4"
              style={{
                alignItems: 'center',
                display: 'flex',
                fontWeight: 500,
                lineHeight: 1,
              }}
            >
              {(!isMenuOpen || isMobile) && (
              <IconButton
                style={{
                  fontSize: '2.125rem',
                  color: MAIN_UI_COLOR,
                  marginRight: '12px',
                  padding: 0,
                }}
                onClick={() => dispatch({ type: !isMenuOpen ? MENU_OPEN : MENU_CLOSE })}
              >
                <AiOutlineMenu />
              </IconButton>
              )}

              {currentPath === '' && ('File Storage')}

              {currentPath !== ''
                && (
                <>
                  <IconButton
                    onClick={() => history.push(backPath)}
                    style={{
                      fontSize: '2.125rem',
                      color: MAIN_UI_COLOR,
                      marginRight: '12px',
                      padding: 0,
                    }}
                  >
                    <HiOutlineArrowLeft />
                  </IconButton>

                  <span
                    style={{ fontSize: '28px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    dangerouslySetInnerHTML={{ __html: currentPath.split('/').slice(-1).pop().replaceAll(' ', '&nbsp;') }}  // eslint-disable-line
                  />
                </>
                )}

            </Typography>
          </Grid>

          <Grid className="buttons-container" container item xs={12} sm={6} justifyContent="flex-end">
            {!(path === '' || path === '/')
            && (
            <Button
              onClick={() => setUploadFileDialogOpen(true)}
              variant="outlined"
              className="button"
              color="primary"
            >
              <IconButton className="outlined-button-icon"><MdUpload /></IconButton>
              Upload files
            </Button>
            )}

            <Button
              onClick={() => setCreateFolderDialogOpen(true)}
              variant="contained"
              className="button"
              color="primary"
            >
              <IconButton className="contained-button-icon"><BiPlus /></IconButton>
              Create Folder
            </Button>

            {!(path === '' || path === '/')
            && (
            <ConfirmButtonV2Dialog
              buttonProps={{
                disabled: selectedIds.length > 0 || protectedFolders.indexOf((path.startsWith('/') ? path.slice(1) : path).split('/')[0]) > -1 || !configuration.obj.allowsToDelete,
                style: { color: '#ffffff' },
                variant: 'contained',
                className: 'button',
                color: 'secondary',
              }}
              buttonText={(
                <>
                  <IconButton className="contained-button-icon">
                    <MdDeleteForever style={{ margin: '0 0 0 -5px' }} />
                  </IconButton>
                  Remove
                </>
              )}
              confirmText="Permanently delete this folder and all of its contents?"
              onConfirm={() => onConfirmDelete()}
              confirmButtonText="Remove"
            />
            )}
          </Grid>

          <Grid item xs={12} className="pt-4">
            <Box sx={{ boxShadow: 0 }}>
              <TextField
                onChange={event => setSearchValue(event.target.value)}
                placeholder="Search a file or a folder by name"
                InputProps={{
                  style: {
                    background: 'rgba(51, 51, 51, 0.05)',
                    paddingBottom: '2.5px',
                    paddingTop: '2.5px',
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <HiOutlineSearch style={{ color: 'grey' }} />
                    </InputAdornment>
                  ),
                }}
                value={searchValue}
                variant="outlined"
                size="small"
                id="search"
                fullWidth
              />
            </Box>
          </Grid>
        </Grid>
      </div>

      <div style={{ paddingLeft: '60px', paddingRight: '60px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RyffineTable
              tableContainerProps={{ style: { paddingTop: '24px' } }}
              actionType={ACTION_TYPE_NONE}
              columns={tableColumns}
              loading={loading}
              rows={tableRows}
            />
          </Grid>
        </Grid>
      </div>

      <CreateFolderDialog
        open={createFolderDialogOpen}
        onClose={() => setCreateFolderDialogOpen(false)}
        onSubmit={() => dispatch(fetchObjectsFromPath(path))}
        currentPath={currentPath}
      />

      <UploadFilesDialog
        open={uploadFileDialogOpen}
        onClose={() => setUploadFileDialogOpen(false)}
        onSubmit={() => dispatch(fetchObjectsFromPath(path))}
        dirPath={currentPath}
      />

      {openBulkDeleteDialog && (
        <BulkDeleteDialog
          pathes={selectedIds}
          open={openBulkDeleteDialog}
          onClose={() => {
            setOpenBulkDeleteDialog(false);
            setSelectedIds([]);

            dispatch(fetchObjectsFromPath(path));
          }}
        />
      )}
    </>
  );
}

export default FileStorage;

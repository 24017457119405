import {
  FETCH_EVALUATION_SCORE_START,
  FETCH_EVALUATION_SCORE_SUCCESS,
  FETCH_EVALUATION_SCORE_FAIL,

} from '../actionTypes';

const initialState = {
  scores: [],
  loading: false,
};

export default function evaluationReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EVALUATION_SCORE_START:
      return { ...state, loading: true };
    case FETCH_EVALUATION_SCORE_SUCCESS:
      return {
        ...state,
        scores: action.payload.scores,
        loading: false,
      };
    case FETCH_EVALUATION_SCORE_FAIL:
      return {
        ...state,
        scores: [],
        loading: false,
      };

    default:
      return state;
  }
}

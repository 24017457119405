import {
  FETCH_EVALUATION_SCORE_START,
  FETCH_EVALUATION_SCORE_SUCCESS,
  FETCH_EVALUATION_SCORE_FAIL,
} from '../actionTypes';

import { actionFail } from './appActions';
import { readZipFileContent } from './fileStorageActions';

export function fetchEvaluationScores(taskId) {
  return function (dispatch) {
    const filepath = `ryffine-reports/evaluation/${taskId}.zip`;

    dispatch({ type: FETCH_EVALUATION_SCORE_START });

    return readZipFileContent(filepath)
      .then(zip => zip.file(Object.keys(zip.files)[0]).async('string'))
      .then(text => dispatch({
        type: FETCH_EVALUATION_SCORE_SUCCESS,
        payload: JSON.parse(text),
      }))
      .catch(error => {
        dispatch(actionFail(FETCH_EVALUATION_SCORE_FAIL, error.response));
      });
  };
}

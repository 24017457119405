import axios from 'axios';
import JSZip from 'jszip';

import {
  FETCH_OBJECTS_FROM_PATH_START,
  FETCH_OBJECTS_FROM_PATH_SUCCESS,
  FETCH_OBJECTS_FROM_PATH_FAIL,
  DOWNLOADING_FILE_START,
  DOWNLOADING_FILE_FINISH,
  FETCH_FILE_TAGS_START,
  FETCH_FILE_TAGS_SUCCESS,
  FETCH_FILE_TAGS_FAIL,
  FETCH_ZIP_FILE_LIST_START,
  FETCH_ZIP_FILE_LIST_SUCCESS,
  FETCH_ZIP_FILE_LIST_FAIL,
  FETCH_FILE_STORAGE_CONFIGURATION_START,
  FETCH_FILE_STORAGE_CONFIGURATION_SUCCESS,
  FETCH_FILE_STORAGE_CONFIGURATION_FAIL,
} from '../actionTypes';

import { FILES_API_URL } from '../../config';
import { actionFail } from './appActions';
import { ENTITY_TYPE_FILE, ENTITY_TYPE_FOLDER } from '../../core/entities';
import { parseBasePathEntity, parseUploadURLEntity } from '../../core/parsers';
import { parsePath } from '../../core/services/fileStorageService';

export function fetchObjectsFromPath(path = '') {
  return function (dispatch) {
    dispatch({ type: FETCH_OBJECTS_FROM_PATH_START });

    return axios.get(`${FILES_API_URL}/path${path}`)
      .then(res => {
        const payload = res.data.data;
        dispatch({ type: FETCH_OBJECTS_FROM_PATH_SUCCESS, payload });
      })
      .catch(error => {
        dispatch(actionFail(FETCH_OBJECTS_FROM_PATH_FAIL, error.response));
      });
  };
}

export function downloadFile(filepath) {
  return function (dispatch) {
    dispatch({ type: DOWNLOADING_FILE_START });

    return fetchDownloadURL(filepath)
      .then(url => {
        window.location.href = url;
      })
      .catch(error => {
        dispatch(actionFail(null, error.response));
      })
      .finally(() => dispatch({ type: DOWNLOADING_FILE_FINISH }));
  };
}

export function uploadFile(dirPath, file, expiration = 60) {
  return function (dispatch) {
    return fetchUploadURL(dirPath, file.normalizedName || file.name, expiration)
      .then(res => parseUploadURLEntity(res.data.data))
      .then(uploadURL => uploadToURL(uploadURL, file))
      .catch(error => {
        dispatch(actionFail(null, error.response));
        return Promise.reject(error.response);
      });
  };
}

export function listZipFile(filepath, dirname) {
  return function (dispatch) {
    dispatch({ type: FETCH_ZIP_FILE_LIST_START });

    return axios.get(`${FILES_API_URL}/zipfile/${filepath}?dirname=${dirname}`)
      .then(res => {
        const payload = res.data.data;
        dispatch({ type: FETCH_ZIP_FILE_LIST_SUCCESS, payload });
      })
      .catch(error => {
        dispatch(actionFail(FETCH_ZIP_FILE_LIST_FAIL, error.response));
      });
  };
}

export function fetchFileTags(filepath) {
  return function (dispatch) {
    dispatch({ type: FETCH_FILE_TAGS_START });

    return axios.get(`${FILES_API_URL}/tags/${filepath}`)
      .then(res => {
        const payload = res.data.data;
        dispatch({ type: FETCH_FILE_TAGS_SUCCESS, payload });
      })
      .catch(error => {
        dispatch(actionFail(FETCH_FILE_TAGS_FAIL, error.response));
      });
  };
}

export function createFolder(basename, dirname) {
  return function () {
    const body = {
      data: {
        type: ENTITY_TYPE_FOLDER,
        attributes: {
          basename: basename === '/' ? '' : basename.replace(/^\//, ''),
          dirname,
        },
      },
    };

    return axios.post(`${FILES_API_URL}/path`, body)
      .then(res => res.data.data)
      .catch(error => Promise.reject(error.response.data));
  };
}

export function readZipFileContent(filepath) {
  return fetchDownloadURL(filepath)
    .then(url => fetch(url))
    .then(res => res.blob())
    .then(JSZip.loadAsync);
}

function fetchUploadURL(dirPath, filename, expiration) {
  const basename = dirPath.startsWith('/') ? dirPath.substr(1) : dirPath;

  const body = {
    data: {
      type: ENTITY_TYPE_FILE,
      attributes: {
        basename,
        filename,
      },
    },
  };

  return axios.post(`${FILES_API_URL}/upload/?expiration=${expiration}`, body);
}

function fetchDownloadURL(filepath, expiration = 60) {
  const [dirname, filename] = parsePath(filepath);
  const body = {
    data: {
      type: ENTITY_TYPE_FILE,
      attributes: {
        basename: dirname,
        filename,
      },
    },
  };

  return axios.post(`${FILES_API_URL}/download/?expiration=${expiration}`, body)
    .then(res => res.data.data.attributes.url);
}

export function checkFileExist(path) {
  return function () {
    return axios.get(`${FILES_API_URL}/path/${path}`)
      .then(res => {
        if (res.status === 200 && res.data.data.type === ENTITY_TYPE_FILE) {
          return Promise.resolve(res);
        }

        return Promise.reject();
      });
  };
}

export function getPathEntity(path) {
  return axios.get(`${FILES_API_URL}/path/${path}`)
    .then(res => Promise.resolve(res))
    .catch(err => Promise.resolve(err.response));
}

function uploadToURL(uploadUrl, file) {
  const data = new FormData();
  data.append('key', uploadUrl.key);
  data.append('AWSAccessKeyId', uploadUrl.accessKeyId);
  data.append('policy', uploadUrl.policy);
  data.append('signature', uploadUrl.signature);

  if (uploadUrl.xAmzSecurityToken) {
    data.append('x-amz-security-token', uploadUrl.xAmzSecurityToken);
  }

  data.append('file', file);

  return fetch(uploadUrl.url, {
    method: 'POST',
    mode: 'no-cors',
    body: data,
  });
}

export function readFile(path) {
  return function () {
    return axios.get(`${FILES_API_URL}/read/${path}`)
      .then(res => {
        if (res.status === 200) return Promise.resolve(res);
        return Promise.reject();
      });
  };
}

export function getObjectsFromPath(path = '') {
  return axios.get(`${FILES_API_URL}/path${path}`)
    .then(res => {
      const payload = res.data.data;

      return Promise.resolve(Array.isArray(payload) ? payload.map(parseBasePathEntity) : []);
    })
    .catch(() => Promise.resolve([]));
}

export function deletePathObject(filepath) {
  return axios.delete(`${FILES_API_URL}/path/${filepath}`)
    .then(() => Promise.resolve(true))
    .catch(error => Promise.reject(error.response.data));
}

export function fetchFileStorageConfiguration() {
  return function (dispatch) {
    dispatch({ type: FETCH_FILE_STORAGE_CONFIGURATION_START });

    return axios.get(`${FILES_API_URL}/configuration`)
      .then(res => {
        const payload = res.data.data;
        dispatch({ type: FETCH_FILE_STORAGE_CONFIGURATION_SUCCESS, payload });
      })
      .catch(error => {
        dispatch(actionFail(FETCH_FILE_STORAGE_CONFIGURATION_FAIL, error.response));
      });
  };
}

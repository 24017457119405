import React, { useEffect, useState } from 'react';
import { HiOutlineSearch } from 'react-icons/hi';
import {
  MdOutlineTextSnippet,
  MdOutlineArrowForwardIos,
  MdInfoOutline,
  MdFolderOpen,
  MdDelete,
  MdWarning,
} from 'react-icons/md';
import { AiFillCopy, AiOutlineClear } from 'react-icons/ai';
import { BsPencilSquare } from 'react-icons/bs';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  Dialog,
  DialogContent,
  Stepper,
  Select,
  Step,
  StepLabel,
  StepContent,
  Typography,
  TextField,
  Button,
  Grid,
  MenuItem,
  ListItemText,
  Checkbox,
  makeStyles,
  FormControl,
  FormHelperText,
  FormControlLabel,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Snackbar,
  DialogActions,
} from '@material-ui/core';
import SelectParameterFile from './SelectParameterFile';
import Placeholder from '../common/Placeholder';
import RyffineDialogTitle from '../common/RyffineDialogTitle';
import LoadingButton from '../common/LoadingButton';
import Loader from '../common/Loader';
import { ENTITY_TYPE_FOLDER } from '../../core/entities';
import { MAIN_UI_COLOR, SECONDARY_UI_COLOR } from '../../config';
import HtmlTooltip from '../common/HtmlTooltip';

const useStyles = makeStyles(() => ({
  field: {
    '& .MuiInputBase-root': {
      '& .MuiInputBase-input': {
        padding: '8px 0px 8px 16px',
      },
      '& .MuiButtonBase-root': {
        padding: '8px',
      },
      borderRadius: '4px',
      background: '#FFFFFF',
      padding: 0,
    },
  },
  list: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 150,
    minHeight: 150,
    height: 150,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  notchedOutline: {
    border: '1px solid #bdbdbd',
  },
  formControlRoot: {
    '& .MuiInputBase-root': {
      borderRadius: '4px',
    },
  },
  selectRoot: {
    background: '#FFFFFF',
    padding: '8px 0px 8px 16px',
  },
  indeterminateColor: {
    color: SECONDARY_UI_COLOR,
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));

const STEP_FIELD_TYPE_MULTIPLE = 'multiple';
const STEP_FIELD_TYPE_PATH = 'path';

const SelectMenuProps = { variant: 'menu' };

function CreateTaskDialog(props) {
  const {
    open,
    config,
    loading,
    onClose,
    resetOnClose,
  } = props;
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(config.initActiveStep || 0);

  const [currentSelectFile, setCurrentSelectFile] = useState({});
  const [openSelectFileDialog, setOpenSelectFileDialog] = useState(false);

  const [processingSubmit, setProcessingSubmit] = useState(false);
  const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);

  const currentActiveStep = activeStep > config.steps.length - 1
    ? config.steps.length - 1
    : activeStep;

  const allowCreateTask = (
    config.steps.map(s => s.allowContinue && s.isValid).every(i => i === true)
  );

  useEffect(() => {
    if (config.initActiveStep !== currentActiveStep) setActiveStep(config.initActiveStep);
  }, [config.initActiveStep]);

  const onDialogClose = () => {
    if (resetOnClose) setActiveStep(0);

    onClose();
  };

  const handleNext = step => {
    setProcessingSubmit(true);

    config.steps[step].onSubmit().then(value => {
      if (value && config.steps[step].isValid) setActiveStep(step + 1);

      setProcessingSubmit(false);
    });
  };

  const handleBack = () => {
    if (currentActiveStep !== 0) setActiveStep(currentActiveStep - 1);
    else onDialogClose();
  };

  const handleCreateTask = () => config.onSubmit();

  const renderInactiveField = field => {
    if (field.disabled) return '';

    if ((Array.isArray(field.value) && field.value.length > 0)) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography style={{ fontSize: '14px', overflowWrap: 'break-word', fontWeight: 500 }}>
              {field.label}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} style={{ overflowWrap: 'break-word' }}>
            {Array.isArray(field.value) && field.value.join(', ')}
          </Grid>
        </Grid>
      );
    }

    if (field.value !== undefined && (typeof field.value === 'string' || typeof field.value === 'number')) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography style={{ fontSize: '14px', overflowWrap: 'break-word', fontWeight: 500 }}>
              {field.label}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} style={{ overflowWrap: 'break-word' }}>
            {field.type !== 'password'
              && (field.displayValue || (field.value === '' ? 'N/A' : undefined) || field.value)}
            {field.type === 'password' && '******'}
          </Grid>
        </Grid>
      );
    }

    if (!field.value) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography style={{ fontSize: '14px', overflowWrap: 'break-word', fontWeight: 500 }}>
              {field.label}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} style={{ overflowWrap: 'break-word' }}>
            <i>N/A</i>
          </Grid>
        </Grid>
      );
    }

    return '';
  };

  const buildInactiveStepContent = index => {
    const step = config.steps[index];

    if (step.type === STEP_FIELD_TYPE_MULTIPLE) {
      const showConfiguration = (step.configurations || []).map(i => i.value).some(i => i);

      return (
        <>
          {
            step.fields.filter(f => !f.onlyActive).filter(f => !f.renderBottom)
              .map(f => renderInactiveField(f))
          }

          {step.fields.length === 0 && (<i>There are no fields</i>)}

          {showConfiguration
            && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography style={{ fontSize: '14px', fontWeight: 500 }}>
                  Configurations
                </Typography>
              </Grid>
              <Grid item container xs={12} sm={8}>
                {step.configurations.filter(i => i.value).map(i => (
                  <Grid item xs={12}>{i.label}</Grid>
                ))}
              </Grid>
            </Grid>
            )}

          {step.fields.filter(f => f.renderBottom).map(f => renderInactiveField(f))}
        </>
      );
    }

    if (step.type === STEP_FIELD_TYPE_PATH) {
      return (
        <Grid container>
          <Grid item xs={12} style={{ overflowWrap: 'break-word' }}>
            {step.pathField.value && (
              <>
                <HtmlTooltip title="Copy path">
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(step.displayValue || step.pathField.value);
                      setCopySnackbarOpen(true);
                    }}
                    style={{ padding: 0 }}
                  >
                    <AiFillCopy color={MAIN_UI_COLOR} size={18} />
                  </IconButton>
                </HtmlTooltip>
                {' '}
                {!step.displayNode && (
                  <span
                    dangerouslySetInnerHTML={{ __html: (step.displayValue || step.pathField.value).replaceAll(' ', '&nbsp;').replaceAll('/', ' / ') }}  // eslint-disable-line
                  />
                )}
                {step.displayNode && (step.displayNode)}
              </>
            )}

            {!step.pathField.value && <i>Not selected</i>}
          </Grid>

          {step.warning && (
            <Grid className="mt-2" item container xs={12} style={{ color: SECONDARY_UI_COLOR }}>
              <MdWarning size={18} color={SECONDARY_UI_COLOR} />
              {' '}
              {step.warning}
            </Grid>
          )}
        </Grid>
      );
    }

    return <Grid container>Not implemented yet</Grid>;
  };

  const displayPathLinkValue = (value, i, array, step) => {
    if (i !== array.length - 1) {
      return (
        <>
          <Grid item style={{ padding: '0 6px' }}>
            <Typography
              style={{
                color: (value.startsWith(' ') || value.endsWith(' ')) ? '#f44336' : MAIN_UI_COLOR,
                textDecoration: 'underline',
                cursor: 'pointer',
                fontSize: '16px',
              }}
              onClick={() => step.pathField.onChange(`${array.slice(0, i + 1).join('/')}/`)}
            >
              <span
                dangerouslySetInnerHTML={{ __html: value.replaceAll(' ', '&nbsp;') }}  // eslint-disable-line
              />
            </Typography>
          </Grid>
          <Typography style={{ fontSize: '16px' }}>
            /
          </Typography>
        </>
      );
    }

    return '';
  };

  const renderActiveField = (field, i) => (
    <Grid
      style={
        i % 2
          ? { paddingLeft: '12px', marginBottom: '10px' }
          : { paddingRight: '12px', marginBottom: '10px' }
        }
      container
      xs={6}
      item
    >
      <Grid item container style={{ maxHeight: '21px' }}>
        <Grid container>
          <Grid item xs={10}>
            <Typography style={{ fontSize: '14px', fontWeight: 500 }}>
              {field.label}
              {' '}
              {field.tooltip
                  && (
                    <HtmlTooltip title={field.tooltip}>
                      <IconButton className="outlined-button-icon" onClick={field.tooltipOnClick || undefined}>
                        <MdInfoOutline size="16px" />
                      </IconButton>
                    </HtmlTooltip>
                  )}
            </Typography>
          </Grid>

          <Grid item xs={2} container justifyContent="flex-end">
            {field.onClear && (
            <HtmlTooltip title={field.tooltipOnClear || 'Clear'}>
              <IconButton style={{ padding: 0 }} onClick={field.onClear}>
                <AiOutlineClear size={16} color={MAIN_UI_COLOR} />
              </IconButton>
            </HtmlTooltip>
            )}
            {field.onDelete && (
            <HtmlTooltip title={field.tooltipOnDelete || 'Remove'}>
              <IconButton
                className={field.onClear ? 'ml-1' : ''}
                onClick={field.onDelete}
                style={{ padding: 0 }}
              >
                <MdDelete size={16} color={SECONDARY_UI_COLOR} />
              </IconButton>
            </HtmlTooltip>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item container style={{ padding: '5px 0' }}>
        <FormControl
          classes={{ root: classes.formControlRoot }}
          error={Boolean(field.error)}
          fullWidth
        >
          {!field.multiple
            && (
              <TextField
                InputProps={{
                  endAdornment: field.type === 'file' ? field.button || (
                    <Button
                      style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                      onClick={() => {
                        setCurrentSelectFile(field);
                        setOpenSelectFileDialog(true);
                      }}
                      variant="contained"
                      className="button"
                      color="primary"
                    >
                      Browse
                    </Button>
                  ) : undefined,
                }}
                inputProps={{ // eslint-disable-line
                  renderValue: field.renderValue,
                  displayEmpty: true,
                }}
                disabled={field.disabled || field.type === 'file' || (field.items && field.items.length <= 0)}
                type={field.type === 'password' ? field.type : 'text'}
                select={Object.keys(field).includes('items')}
                error={field.error !== undefined}
                placeholder={field.placeholder}
                renderValue={field.renderValue}
                onChange={field.onChange}
                className={classes.field}
                value={field.value}
                variant="outlined"
                name={field.key}
                id={field.key}
                displayEmpty
              >
                {field.items && field.items.map(field.item)}
              </TextField>
            )}

          {field.multiple
            && (
              <Select
                MenuProps={SelectMenuProps}
                classes={{ root: classes.selectRoot }}
                renderValue={field.renderValue || (selected => {
                  if (selected.length === 0) return <Placeholder>Select</Placeholder>;

                  return selected.join(', ');
                })}
                disabled={field.disabled || (field.items && field.items.length <= 0)}
                onChange={field.onChange}
                value={field.value}
                variant="outlined"
                name={field.key}
                id={field.key}
                displayEmpty
                multiple
              >
                {field.enableSelectAll && (
                  <MenuItem
                    style={{ borderBottom: `1px solid ${MAIN_UI_COLOR}` }}
                    classes={{
                      root: field.items.length > 0 && field.value.length === field.items.length ? classes.selectedAll : '',
                    }}
                    value="all"
                  >
                    <Checkbox
                      checked={field.items.length > 0 && field.value.length === field.items.length ? classes.selectedAll : ''}
                      classes={{ indeterminate: classes.indeterminateColor }}
                      style={{ marginRight: '9px', padding: '0' }}
                      indeterminate={
                        field.value.length < field.items.length
                        && field.value.length > 0
                      }
                    />
                    <ListItemText
                      primary={`${field.label}${field.value.length > 0 ? ` (${field.value.length})` : ''}`}
                      classes={{ primary: classes.selectAllText }}
                    />
                  </MenuItem>
                )}
                {field.items && field.items.map(field.item)}
              </Select>
            )}
        </FormControl>
      </Grid>
    </Grid>
  );

  const buildActiveStepContent = index => {
    const step = config.steps[index];

    const errors = (step.fields || [])
      .filter(field => field.error && Boolean(field.error) && field.value !== '').map(field => field.error)
      .sort((a, b) => b.length - a.length);

    if (step.type === STEP_FIELD_TYPE_PATH && !step.isValid && step.pathField.value !== '') {
      errors.push(step.pathField.error);
    }

    if (step.error && step.error !== '') errors.push(step.error);

    return (
      <>
        <Grid container>
          {step.type === STEP_FIELD_TYPE_MULTIPLE
            && (
            <>
              {step.fields.length <= 0
                && (
                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                  <i>Currently, there are no fields in this step.</i>
                </Grid>
                )}

              {
                step.fields.filter(f => !f.onlyInactive).filter(f => !f.renderBottom)
                  .map((field, i) => renderActiveField(field, i))
              }
            </>
            )}

          {step.type === STEP_FIELD_TYPE_PATH
            && (
            <>
              {step.fields && step.fields.map(field => (
                <Grid container xs={12} item style={{ marginBottom: '10px' }}>
                  <Grid item container style={{ maxHeight: '21px' }}>
                    <Grid container>
                      <Grid item xs={10}>
                        <Typography style={{ fontSize: '14px', fontWeight: 500 }}>
                          {field.label}
                          {' '}
                          {field.tooltip
                            && (
                              <HtmlTooltip title={field.tooltip}>
                                <IconButton className="outlined-button-icon" onClick={field.tooltipOnClick || undefined}>
                                  <MdInfoOutline size="16px" />
                                </IconButton>
                              </HtmlTooltip>
                            )}
                        </Typography>
                      </Grid>

                      <Grid item xs={2} container justifyContent="flex-end">
                        {field.onDelete && (
                          <HtmlTooltip title={field.tooltipOnDelete || 'Remove'}>
                            <IconButton style={{ padding: 0 }} onClick={field.onDelete}>
                              <MdDelete size={16} color={SECONDARY_UI_COLOR} />
                            </IconButton>
                          </HtmlTooltip>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item container style={{ padding: '5px 0' }}>
                    <FormControl
                      classes={{ root: classes.formControlRoot }}
                      error={Boolean(field.error)}
                      fullWidth
                    >
                      {!field.multiple
                        && (
                          <TextField
                            InputProps={{
                              endAdornment: field.type === 'file' ? field.button || (
                                <Button
                                  style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                  onClick={() => {
                                    setCurrentSelectFile(field);
                                    setOpenSelectFileDialog(true);
                                  }}
                                  variant="contained"
                                  className="button"
                                  color="primary"
                                >
                                  Browse
                                </Button>
                              ) : undefined,
                            }}
                            inputProps={{ // eslint-disable-line
                              renderValue: field.renderValue,
                              displayEmpty: true,
                            }}
                            disabled={field.type === 'file' || (field.items && field.items.length <= 0)}
                            type={field.type === 'password' ? field.type : 'text'}
                            select={Object.keys(field).includes('items')}
                            error={field.error !== undefined}
                            placeholder={field.placeholder}
                            renderValue={field.renderValue}
                            onChange={field.onChange}
                            className={classes.field}
                            value={field.value}
                            variant="outlined"
                            name={field.key}
                            id={field.key}
                            displayEmpty
                          >
                            {field.items && field.items.map(field.item)}
                          </TextField>
                        )}

                      {field.multiple
                        && (
                          <Select
                            classes={{ root: classes.selectRoot }}
                            renderValue={field.renderValue || (selected => {
                              if (selected.length === 0) return <Placeholder>Select</Placeholder>;

                              return selected.join(', ');
                            })}
                            disabled={field.items && field.items.length <= 0}
                            onChange={field.onChange}
                            value={field.value}
                            variant="outlined"
                            name={field.key}
                            id={field.key}
                            displayEmpty
                            multiple
                          >
                            {field.items && field.items.map(field.item)}
                          </Select>
                        )}
                    </FormControl>
                  </Grid>
                </Grid>
              ))}

              {step.pathField.label && (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography style={{ fontSize: '14px', fontWeight: 500 }}>
                      {step.pathField.label}
                      {' '}
                      {step.pathField.tooltip
                      && (
                        <HtmlTooltip title={step.pathField.tooltip}>
                          <IconButton className="outlined-button-icon" onClick={step.pathField.tooltipOnClick || undefined}>
                            <MdInfoOutline size="16px" />
                          </IconButton>
                        </HtmlTooltip>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              <Grid
                container
                style={{
                  marginBottom: '20px',
                  borderRadius: '4px',
                  background: 'white',
                }}
              >
                <Grid container item xs={12}>
                  <Grid
                    container
                    item
                    xs={12}
                    style={{
                      border: '1px solid rgba(51, 51, 51, 0.3)',
                      borderRadius: '4px 4px 0 0',
                      padding: '8px 12px',
                      background: '#FFFFFF',
                    }}
                  >
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{
                            padding: 0, border: 'none', width: '20px', verticalAlign: 'top',
                          }}
                          >
                            <HiOutlineSearch style={{ color: 'grey' }} size={20} />
                          </TableCell>
                          <TableCell style={{ padding: 0, border: 'none' }}>
                            <Grid container>
                              <Grid item style={{ padding: '0 6px' }}>
                                <Typography
                                  onClick={() => step.pathField.onChange('')}
                                  style={{
                                    textDecoration: 'underline',
                                    color: MAIN_UI_COLOR,
                                    cursor: 'pointer',
                                    fontSize: '16px',
                                  }}
                                >
                                  File Storage
                                </Typography>
                              </Grid>
                              <Typography style={{ fontSize: '16px' }}>
                                /
                              </Typography>

                              {step.pathField.value.split('/').map((value, i, array) => displayPathLinkValue(value, i, array, step))}

                              <Grid item>
                                <InputBase
                                  inputProps={{
                                    'aria-label': 'naked',
                                    placeholder: step.pathField.placeholder || 'Search a file or a folder by name',
                                    style: {
                                      width: `${step.pathField.value.split('/').at(-1).length * 8.9}px`,
                                      lineHeight: '24px',
                                      minWidth: '240px',
                                      maxWidth: '650px',
                                      padding: '0 6px',
                                    },
                                  }}
                                  onChange={event => {
                                    const splitValue = step.pathField.value.split('/');

                                    splitValue[splitValue.length - 1] = event.target.value;

                                    step.pathField.onChange(splitValue.join('/'));
                                  }}
                                  onKeyDown={event => {
                                    if (event.key === 'Backspace') {
                                      const splitValue = step.pathField.value.split('/');

                                      if (splitValue.at(-1).length === 0) {
                                        step.pathField.onChange(`${splitValue.slice(0, splitValue.length - 1).join('/')}`);
                                        event.preventDefault();
                                      }
                                    }
                                  }}
                                  onPaste={event => {
                                    event.preventDefault();

                                    step.pathField.onChange(`${step.pathField.value}${event.clipboardData.getData('Text').replaceAll(' ', '')}`);
                                  }}
                                  value={step.pathField.value.split('/').at(-1)}
                                  style={{ padding: 0 }}
                                  multiline
                                />
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>

                <Grid item xs={12} style={{ border: '1px solid rgba(51, 51, 51, 0.3)', borderTop: 0, borderRadius: '0 0 4px 4px' }}>
                  {step.loading
                  && (
                  <Grid style={{ minHeight: 150, maxHeight: 150, height: 150 }}>
                    <Loader />
                  </Grid>
                  )}

                  {!step.loading && step.pathField.objects.length > 0
                  && (
                  <List className={classes.list}>
                    {!step.loading && step.pathField.objects.map(obj => {
                      if (obj.type === ENTITY_TYPE_FOLDER) {
                        return (
                          <ListItem key={`item-${obj.name}`}>
                            <Grid container onClick={() => step.pathField.onChange(`${obj.id}/`)}>
                              <Grid container item xs={11} style={{ height: '20px' }}>
                                <MdFolderOpen size={18} style={{ marginRight: '5px' }} />
                                <span
                                  dangerouslySetInnerHTML={{ __html: obj.name.replaceAll(' ', '&nbsp;') }}  // eslint-disable-line
                                />
                              </Grid>

                              <Grid container item xs={1} justifyContent="flex-end" style={{ height: '20px' }}>
                                <IconButton style={{ padding: 0 }}>
                                  <MdOutlineArrowForwardIos size={18} />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </ListItem>
                        );
                      }

                      return (
                        <ListItem key={`item-${obj.name}`}>
                          <Grid container onClick={() => step.pathField.onChange(obj.id)}>
                            <MdOutlineTextSnippet size={18} style={{ marginRight: '5px' }} />
                            <span
                              dangerouslySetInnerHTML={{ __html: obj.name.replaceAll(' ', '&nbsp;') }}  // eslint-disable-line
                              style={{ width: '95%', overflowWrap: 'break-word' }}
                            />
                          </Grid>
                        </ListItem>
                      );
                    })}
                  </List>
                  )}

                </Grid>
              </Grid>
            </>
            )}

          {step.configurations && step.configurations.length > 0
            && (
            <Grid
              style={{ paddingRight: '12px', marginBottom: '10px' }}
              container
              xs={12}
              item
            >
              <Grid item container style={{ maxHeight: '21px' }}>
                <Typography style={{ fontSize: '14px', fontWeight: 500 }}>
                  Configurations
                </Typography>
              </Grid>

              {step.configurations.map(configuration => (
                <Grid item container>
                  <FormControl size="small">
                    <FormControlLabel
                      label={(
                        <Typography style={{ fontSize: '14px', marginTop: '0px' }}>
                          {configuration.label}
                          {' '}
                          {configuration.tooltip
                            && (
                              <HtmlTooltip title={configuration.tooltip}>
                                <IconButton className="outlined-button-icon" onClick={configuration.tooltipOnClick || undefined}>
                                  <MdInfoOutline size="16px" />
                                </IconButton>
                              </HtmlTooltip>
                            )}
                        </Typography>
                      )}
                      control={(
                        <Checkbox
                          onChange={event => configuration.onChange(event)}
                          style={{ marginLeft: '6px', padding: '3px' }}
                          disabled={configuration.disabled}
                          checked={configuration.value}
                          value={configuration.value}
                          name={configuration.key}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              ))}
            </Grid>
            )}
        </Grid>

        {step.type === STEP_FIELD_TYPE_MULTIPLE
          && step.fields.filter(f => f.renderBottom).map((field, i) => renderActiveField(field, i))}

        {step.stepTooltip
          && (
          <Grid className="mb-2" container>
            <Grid item xs={12}>
              {step.stepTooltip}
            </Grid>
          </Grid>
          )}

        <Grid container>
          <Grid item xs={6}>
            {currentActiveStep < config.steps.length - 1
              && (
              <LoadingButton
                disabled={
                  config.loading
                  || step.loading
                  || !(step.allowContinue && step.isValid)
                }
                onClick={() => handleNext(index)}
                style={{ minWidth: '110px' }}
                loading={processingSubmit}
                variant="contained"
                className="button"
                color="primary"
              >
                Next Step
              </LoadingButton>
              )}

            <Button
              style={{ marginLeft: currentActiveStep < config.steps.length - 1 ? '10px' : 0 }}
              onClick={handleBack}
              className="button"
              color="primary"
            >
              {currentActiveStep === 0 ? 'Close' : 'Prev Step'}
            </Button>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            {step.additionalButton && step.additionalButton}
          </Grid>
        </Grid>

        {step.warning && (
          <Grid className="mt-2" container style={{ color: SECONDARY_UI_COLOR }}>
            <MdWarning size={18} color={SECONDARY_UI_COLOR} />
            {' '}
            {step.warning}
          </Grid>
        )}

        <Grid className="mt-2" container>
          {errors.map(error => (
            <Grid item xs={12}>
              <FormHelperText error>{error}</FormHelperText>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  let loadingDialog = false;
  if (loading && activeStep > config.steps.length - 1) loadingDialog = true;

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onDialogClose} open={open}>
      <RyffineDialogTitle onClose={onDialogClose}>
        {config.title}
      </RyffineDialogTitle>

      {loadingDialog && <Loader />}

      {!loadingDialog && (
        <DialogContent style={{ background: '#F8F8F8', paddingBottom: 0 }}>
          <Stepper
            style={{ padding: '12px 0 0 0', background: '#F8F8F8' }}
            activeStep={activeStep}
            orientation="vertical"
          >
            {config.steps.map((step, index) => (
              <Step
                active={
                (config.seeValuesAfterActiveStep && step.allowContinue && step.isValid)
                || (index < currentActiveStep || index === currentActiveStep)
              }
                style={{ paddingBottom: index === config.steps.length - 1 ? '10px' : 0 }}
                key={step.title}
              >
                <StepLabel
                  error={index < currentActiveStep && (!step.allowContinue || !step.isValid)}
                >
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography style={{ fontSize: '16px', fontWeight: 500 }}>
                        {step.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} container justifyContent="flex-end">
                      {config.allowAnyStepSelection
                      && (index < activeStep || index !== currentActiveStep)
                      && (
                      <HtmlTooltip title={`Edit ${config.steps[index].title}`}>
                        <IconButton
                          disabled={
                            !config.steps[currentActiveStep].allowContinue
                            || !config.steps[currentActiveStep].isValid
                          }
                          onClick={() => setActiveStep(index)}
                          style={{ padding: '0 8px' }}
                        >
                          <BsPencilSquare
                            color={
                              (
                                config.steps[currentActiveStep].allowContinue
                                && config.steps[currentActiveStep].isValid
                              )
                                ? MAIN_UI_COLOR : '#AAAAAA'
                            }
                            size={16}
                          />
                        </IconButton>
                      </HtmlTooltip>
                      )}
                    </Grid>
                  </Grid>
                </StepLabel>
                <StepContent
                  style={{
                    borderLeft: index === config.steps.length - 1 ? 'none' : '1px solid #bdbdbd',
                  }}
                >
                  {(index !== activeStep) && buildInactiveStepContent(index)}
                  {index === activeStep && buildActiveStepContent(index)}
                </StepContent>
              </Step>
            ))}
          </Stepper>

          <SelectParameterFile
            onClose={() => setOpenSelectFileDialog(false)}
            open={openSelectFileDialog}
            field={currentSelectFile}
          />

        </DialogContent>
      )}

      {!loadingDialog && (
        <DialogActions style={{ padding: 0 }}>
          <Grid
            container
            style={{
              padding: '16px 24px 16px 24px',
              background: MAIN_UI_COLOR,
            }}
          >
            <Grid item xs={12}>
              <LoadingButton
                className="button create-task-button"
                style={{
                  background: 'rgb(248, 248, 248)',
                  minWidth: '110px',
                }}
                disabled={!allowCreateTask}
                onClick={handleCreateTask}
                loading={config.loading}
                variant="outlined"
                color="primary"
              >
                Create Task
              </LoadingButton>

              <Button
                style={{ marginLeft: '10px', color: '#ffffff' }}
                onClick={onDialogClose}
                className="button"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message="The relative path has been copied!"
        onClose={() => setCopySnackbarOpen(false)}
        open={copySnackbarOpen}
      />
    </Dialog>
  );
}

CreateTaskDialog.defaultProps = { loading: false, resetOnClose: true };

CreateTaskDialog.propTypes = {
  loading: PropTypes.bool,
  resetOnClose: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
};

export default CreateTaskDialog;

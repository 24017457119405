class BaseEntity {
  constructor(id, type) {
    this.id = id;
    this.type = type;
  }
}

export const ENTITY_TYPE_FOLDER = 'folder';
export const ENTITY_TYPE_FILE = 'file';
export const ENTITY_TYPE_LOG_EVENT = 'log_event';
export const ENTITY_TYPE_TAG = 'tag';
export const ENTITY_TYPE_TASK = 'task';
export const ENTITY_TYPE_TASK_STATE = 'task-state';
export const ENTITY_TYPE_PROGRESS = 'progress';
export const ENTITY_TYPE_UPLOAD_URL = 'upload_url';

export const TASK_STATUS_FINISHED = 'FINISHED';

export const TASK_STATUS_RUNNING = 'RUNNING';
export const TASK_STATUS_STOPPED = 'STOPPED';
export const TASK_STATUS_INTERRUPTED = 'INTERRUPTED';
export const TASK_STATUS_DEPROVISIONING = 'DEPROVISIONING';

export const TASK_ENGINE_XSLT = 'xslt';
export const TASK_ENGINE_PYTHON = 'python';
export const TASK_ENGINE_OXYGEN = 'oxygen';
export const TASK_ENGINE_PANDOC = 'pandoc';
export const TASK_ENGINE_INTEGRATOR = 'integrator';

export const TASK_CONVERTER_NORMALIZATION = 'normalization';

export const TASK_CONVERTER_IXIASOFT_IMPORT = 'ixiasoft-import';
export const TASK_CONVERTER_IXIASOFT_EXPORT = 'ixiasoft-export';

export const TASK_CONVERTER_GITHUB_UPLOAD = 'github-upload';
export const TASK_CONVERTER_GITHUB_DOWNLOAD = 'github-download';

export const TASK_CONVERTER_PALIGO_PUBLISH = 'paligo-publish';
export const TASK_CONVERTER_PALIGO_IMPORT = 'paligo-import';

export const TASK_CONVERTER_SDL_IMPORT = 'sdl-import';
export const TASK_CONVERTER_SDL_EXPORT = 'sdl-export';

export const TASK_CONVERTER_KION_SDL_IMPORT = 'kion-sdl-import';
export const TASK_CONVERTER_KION_SDL_EXPORT = 'kion-sdl-export';

export class BasePathEntity extends BaseEntity {
  constructor(id, type, basename) {
    super(id, type);
    this.basename = basename;
  }

  // eslint-disable-next-line
  get name() {
    throw Error('Method name is not implemented');
  }

  isDir() {
    return this.type === ENTITY_TYPE_FOLDER;
  }

  isFile() {
    return this.type === ENTITY_TYPE_FILE;
  }
}

export class FolderEntity extends BasePathEntity {
  constructor(id, basename, dirname) {
    super(id, ENTITY_TYPE_FOLDER, basename);
    this.dirname = dirname;
  }

  get name() {
    return this.dirname;
  }

  // eslint-disable-next-line
  get size() {
    return 0;
  }

  // eslint-disable-next-line
  get updated() {
    return '';
  }
}

export class FileEntity extends BasePathEntity {
  constructor(id, basename, filename, size, updated) {
    super(id, ENTITY_TYPE_FILE, basename);
    this.filename = filename;
    this.size = size;
    this.updated = updated;
  }

  get name() {
    return this.filename;
  }
}

export class EventEntity extends BaseEntity {
  constructor(id, timestamp, message) {
    super(id, ENTITY_TYPE_LOG_EVENT);
    this.timestamp = timestamp;
    this.message = message;
  }
}

export class TagEntity extends BaseEntity {
  constructor(id, key, value, path) {
    super(id, ENTITY_TYPE_TAG);
    this.key = key;
    this.value = value;
    this.path = path;
  }
}

export class TaskEntity extends BaseEntity {
  constructor(
    id,
    engine,
    converter,
    tag,
    owner,
    source,
    output,
    status,
    created,
    started,
    finished,
    ram,
    cpu,
    config,
    expectedResult,
    env,
  ) {
    super(id, ENTITY_TYPE_TASK);
    this.engine = engine;
    this.converter = converter;
    this.tag = tag;
    this.owner = owner;
    this.source = source;
    this.output = output;
    this.status = status === 'STOPPED' ? TASK_STATUS_FINISHED : status;
    this.created = created;
    this.started = started;
    this.finished = finished;
    this.ram = ram;
    this.cpu = cpu;
    this.config = config;
    this.expectedResult = expectedResult;
    this.env = env;
  }
}

export class TaskStateEntity {
  constructor(hasErrors, hasWarnings) {
    this.hasErrors = hasErrors;
    this.hasWarnings = hasWarnings;
  }
}

export class UploadURLEntity extends BaseEntity {
  constructor(
    id,
    url,
    bucket,
    key,
    expiration,
    created,
    accessKeyId,
    policy,
    signature,
    xAmzSecurityToken,
  ) {
    super(id, ENTITY_TYPE_UPLOAD_URL);
    this.url = url;
    this.bucket = bucket;
    this.key = key;
    this.expiration = expiration;
    this.created = created;
    this.accessKeyId = accessKeyId;
    this.policy = policy;
    this.signature = signature;
    this.xAmzSecurityToken = xAmzSecurityToken;
  }
}

export class ErrorEntity {
  constructor(status, detail) {
    this.status = status;
    this.detail = detail;
  }
}

export class ConversionTypeEntity {
  constructor(engine, name) {
    this.engine = engine;
    this.name = name;
  }
}

export class ConversionTagEntity {
  constructor(name, updated) {
    this.name = name;
    this.updated = updated;
  }
}

export class ProgressEntity {
  constructor(progress, message) {
    this.progress = progress;
    this.message = message;
  }
}

export class IxiasoftDrmOjectEntity {
  constructor(path, title, children) {
    this.path = path;
    this.title = title;
    this.children = children;
  }
}

export class IxiasoftUserEntity {
  constructor(email, name, domain, username) {
    this.name = name;
    this.email = email;
    this.domain = domain;
    this.username = username;
  }
}

export class IxiasoftStatusEntity {
  constructor(status) {
    this.status = status;
  }
}

export class IxiasoftObjectTypeEntity {
  constructor(type, value) {
    this.type = type;
    this.value = value;
  }
}

export class PaligoPublishSettingEntity {
  constructor(id, name, format, resource, createdAt, modifiedAt) {
    this.id = id;
    this.name = name;
    this.format = format;
    this.resource = resource;
    this.createdAt = createdAt;
    this.modifiedAt = modifiedAt;
  }
}

export class PaligoDocumentEntity {
  constructor(id, name, link) {
    this.id = id;
    this.name = name;
    this.link = link;
  }
}

export class SdlFolderEntity {
  constructor(name, folderRef) {
    this.name = name;
    this.folderRef = folderRef;
  }
}

export class UserEntity {
  constructor(id, email, status, enabled, createdAt, updatedAt) {
    this.id = id;
    this.email = email;
    this.status = status;
    this.enabled = enabled;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}

export class FileStorageConfigurationEntity {
  constructor(allowsToDelete, protectedFolders) {
    this.allowsToDelete = allowsToDelete;
    this.protectedFolders = protectedFolders;
  }
}

export class GitHubRepositoryEntity {
  constructor(id, name) {
    this.name = name;
    this.id = id;
  }
}

export class GitHubBranchEntity {
  constructor(name) {
    this.name = name;
  }
}
